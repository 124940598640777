/* eslint-disable */

import { Col, Input, Form, Row, Button, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";

const TabEInvoice = ({
  billChargeInfo,
  invoiceList,
  paymentDetails,
  info,
  setActiveKey,
}) => {
  const [shipmentDataSource, setShipmentDataSource] = useState([]);
  const [invoiceDataSource, setInvoiceDataSource] = useState([]);
  const [listInvoiceDataSource, setListInvoiceDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [seoNumBim, setSeoNumBim] = useState(
    billChargeInfo[0] && billChargeInfo[0].SEQ_NUM_BIP
  );
  const [rowInvoiceNo, setRowInvoiceNo] = useState(
    invoiceList[0] && invoiceList[0].INV_NO
  );
  const [form] = useForm();
  const shipmentColumns = [
    {
      className: "head-awb",
      title: "AWB No",
      dataIndex: "COD_AWB_NUM",
      key: "COD_AWB_NUM",
    },
    {
      className: "head-awb",
      title: "HWB No",
      dataIndex: "COD_HWB_NUM",
      key: "COD_HWB_NUM",
    },
    {
      className: "head-awb",
      title: "ORG",
      dataIndex: "COD_ORI",
      key: "COD_ORI",
    },
    {
      className: "head-awb",
      title: "DES",
      dataIndex: "COD_FDS",
      key: "COD_FDS",
    },
    {
      className: "head-awb",
      title: "Pieces",
      dataIndex: "QTY_TOT_PCS",
      key: "QTY_TOT_PCS",
    },
    {
      className: "head-awb",
      title: "Weight",
      dataIndex: "QTY_GRS_WGT",
      key: "QTY_GRS_WGT",
    },
    {
      className: "head-awb",
      title: "NOG",
      dataIndex: "H25_HAWB_NO",
      key: "H25_HAWB_NO",
    },
    {
      className: "head-awb",
      title: "Amount",
      dataIndex: "parent",
      key: "parent",
      children: [
        {
          className: "head-awb awb-table-currency",
          title: "Gross",
          dataIndex: "AMT_GRS",
          key: "AMT_GRS",
        },
        {
          className: "head-awb awb-table-currency",
          title: "Tax",
          dataIndex: "AMT_TAX",
          key: "AMT_TAX",
        },
        {
          className: "head-awb awb-table-currency",
          title: "Total",
          dataIndex: "AMT_TOT",
          key: "AMT_TOT",
        },
        {
          className: "head-awb awb-table-currency",
          title: "Cash Inv",
          dataIndex: "AMT_CSH_INV",
          key: "AMT_CSH_INV",
        },
        {
          className: "head-awb awb-table-currency",
          title: "Cdt Inv",
          dataIndex: "AMT_CDT_INV",
          key: "AMT_CDT_INV",
        },
        {
          className: "head-awb awb-table-currency",
          title: "Pro Inv",
          dataIndex: "AMT_PRO_INV",
          key: "AMT_PRO_INV",
        },
        {
          className: "head-awb awb-table-currency",
          title: "Oth Inv",
          dataIndex: "AMT_OTH_INV",
          key: "AMT_OTH_INV",
        },
      ],
    },
    {
      className: "head-awb",
      title: "Status",
      dataIndex: "COD_STS",
      key: "COD_STS",
      width: "5%",
    },
    {
      className: "head-awb",
      title: "Online Payment Method",
      dataIndex: "WEB_PMT_MOD",
      key: "WEB_PMT_MOD",
    },
  ];
  const invoiceColumns = [
    {
      title: "Details",
      dataIndex: "parent",
      className: "parent",
      key: "parent",
      children: [
        {
          title: "Charge code",
          dataIndex: "COD_CHG",
          key: "COD_CHG",
        },
        {
          title: "CUR",
          dataIndex: "COD_CUR",
          key: "COD_CUR",
        },
        {
          title: "Bill",
          dataIndex: "COD_BIL",
          key: "COD_BIL",
        },
        {
          title: "Qty",
          dataIndex: "QTY",
          key: "QTY",
        },
        {
          title: "Gross amt",
          dataIndex: "AMT_GROSS",
          key: "AMT_GROSS",
          className: "awb-table-currency",
        },
        {
          title: "VAT amt",
          dataIndex: "AMT_TAX",
          key: "AMT_TAX",
          className: "awb-table-currency",
        },
        {
          title: "Total Amt",
          dataIndex: "AMT_TOTAL",
          key: "AMT_TOTAL",
          className: "awb-table-currency",
        },
      ],
    },
  ];
  const queryColumns = [
    {
      title: "Customer Code",
      dataIndex: "COD_CMP",
      key: "COD_CMP",
      width: "10%",
    },
    {
      title: "Customer Name",
      dataIndex: "NAM_CMP",
      key: "NAM_CMP",
    },
    {
      title: "Tax code",
      dataIndex: "CMP_TAX_REG",
      key: "CMP_TAX_REG",
    },
    {
      title: "AWB No",
      dataIndex: "COD_AWB_NUM",
      key: "COD_AWB_NUM",
    },
    {
      title: "H/M",
      dataIndex: "H/M",
      key: "H/M",
    },
    {
      title: "HAWB No",
      dataIndex: "COD_HWB_NUM",
      key: "COD_HWB_NUM",
    },
    {
      title: "Invoice No",
      dataIndex: "INV_NO",
      key: "INV_NO",
    },
    {
      title: "Serial No",
      dataIndex: "INV_SRL_NO",
      key: "INV_SRL_NO",
    },
    {
      title: "Gross Amt",
      dataIndex: "AMT_GROSS",
      key: "AMT_GROSS",
      className: "awb-table-currency",
    },
    {
      title: "VAT Amt",
      dataIndex: "AMT_TAX",
      key: "AMT_TAX",
      className: "awb-table-currency",
    },
    {
      title: "Total Amt",
      dataIndex: "TOTAL_AMT",
      key: "TOTAL_AMT",
      className: "awb-table-currency",
    },
    {
      title: "Payment method",
      dataIndex: "COD_PAY_MOD",
      key: "COD_PAY_MOD",
    },
  ];
  const showData = () => {
    if (info !== undefined) {
      setLoading(true);
      let data = [];
      data.push({
        COD_AWB_NUM: info.COD_AWB_PFX + info.COD_AWB_NUM,
        COD_HWB_NUM: info.COD_HWB_NUM,
        COD_ORI: info.COD_ORI,
        COD_FDS: info.COD_FDS,
        QTY_TOT_PCS: info.QTY_TOT_PCS,
        QTY_GRS_WGT: info.QTY_GRS_WGT,
        AMT_GRS: info.AMT_GRS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        AMT_TAX: info.AMT_TAX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        AMT_TOT: info.AMT_TOT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        AMT_CSH_INV: info.AMT_CSH_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
        AMT_CDT_INV: info.AMT_CDT_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
        AMT_PRO_INV: info.AMT_PRO_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
        AMT_OTH_INV: info.AMT_OTH_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
        COD_STS: info.COD_STS,
        WEB_PMT_MOD: info.WEB_PMT_MOD,
      });
      setShipmentDataSource(data);
    }
    if (invoiceList !== undefined) {
      setLoading(true);
      let data = [];
      invoiceList.map((dt, index) => {
        data.push({
          key: index,
          COD_CMP: dt.COD_CMP,
          NAM_CMP: dt.NAM_CMP,
          CMP_TAX_REG: dt.CMP_TAX_REG,
          COD_AWB_NUM: dt.COD_AWB_PFX + dt.COD_AWB_NUM,
          "H/M": dt.COD_HWB_NUM === "" ? "M" : "H",
          COD_HWB_NUM: dt.COD_HWB_NUM,
          INV_NO: dt.INV_NO,
          COD_PAY_MOD: dt.COD_PAY_MOD,
          INV_SRL_NO: dt.INV_SRL_NO,
          AMT_TAX: dt.AMT_TAX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          AMT_GROSS: dt.AMT_NET.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ),
          TOTAL_AMT: dt.AMT_TOTAL.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ),
        });
      });
      setListInvoiceDataSource(data);
      setLoading(false);
    }
  };
  const handleRowSelect = (record) => {
    return {
      onClick: () => {
        setRowId(record.key);
        setRowInvoiceNo(record.INV_NO);
        const temp = billChargeInfo.find(
          (dt) =>
            dt.INV_NO === record.INV_NO && dt.INV_SRL_NO === record.INV_SRL_NO
        );
        setSeoNumBim(temp.SEQ_NUM_BIP);
      },
    };
  };
  const showDataRow = () => {
    if (rowId !== undefined) {
      if (invoiceList !== undefined) {
        let data = [];
        setLoading(true);
        let totalGross = 0;
        billChargeInfo.map((dt) => {
          if (dt.INV_NO === rowInvoiceNo) {
            data.push({
              INV_NO: dt.INV_NO,
              INV_SRL_NO: dt.INV_SRL_NO,
              AMT_GROSS: dt.AMT_GROSS,
              AMT_TAX: dt.AMT_TAX,
              AMT_TOTAL: dt.AMT_TOTAL,
              COD_CHG: dt.COD_CHG,
              COD_CUR: dt.COD_CUR,
              COD_BIL: dt.COD_BIL,
              QTY: dt.QTY,
              AMT_TOTAL: (dt.AMT_GROSS + dt.AMT_TAX)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              AMT_GROSS: dt.AMT_GROSS.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              AMT_TAX: dt.AMT_TAX.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
            });
            totalGross += dt.AMT_GROSS + dt.AMT_TAX;
          }
          setTotalGross(
            totalGross.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
          setInvoiceDataSource(data);
          setLoading(false);
        });
      }
      if (seoNumBim !== 0) {
        paymentDetails.map((dt) => {
          if (dt.SEQ_NUM_BIP === seoNumBim) {
            form.setFieldsValue({
              cash: dt.AMT_PAY_CASH.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              credit: dt.AMT_PAY_CREDIT.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              vat: dt.AMT_TAX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              total: dt.AMT_TOTAL.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              net: dt.AMT_NET.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              remark: dt.REMARKS,
            });
            return;
          }
        });
      }
    }
  };
  const prevTab = () => {
    setActiveKey("invoice-info");
  };
  const nextTab = () => {
    setActiveKey("vehicle");
  };
  useEffect(() => {
    showData();
  }, [info, invoiceList]);
  useEffect(() => {
    showDataRow();
  }, [rowId, seoNumBim, rowInvoiceNo]);
  useEffect(() => {
    showDataRow();
  }, [info]);
  return (
    <div>
      <Row>
        <Col span={3}>
          <h2>Shipment details</h2>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={21}>
          <Button
            className="btn-payment"
            style={{ width: "fit-content", marginRight: "5px" }}
            onClick={prevTab}
          >
            {/* {t("header.submit")} */} Quay lại
          </Button>
          <Button
            className="btn-payment"
            style={{ width: "fit-content" }}
            onClick={nextTab}
          >
            {/* {t("header.submit")} */} Tiếp tục
          </Button>
        </Col>
      </Row>
      <Table
        className="table-payment"
        columns={shipmentColumns}
        dataSource={shipmentDataSource}
        bordered
        // scroll={{ x: 1300 }}
        pagination={false}
      />
      <hr />
      <h2>Invoice List</h2>
      <Table
        loading={loading}
        className="table-payment"
        columns={queryColumns}
        dataSource={listInvoiceDataSource}
        bordered
        onRow={handleRowSelect}
        rowClassName={(record) => {
          return record.key === rowId ? "row-selected" : "";
        }}
        // scroll={{ x: 1300 }}
        pagination={false}
      />
      <h2>Invoice Details</h2>
      <Table
        className="table-payment"
        columns={invoiceColumns}
        dataSource={invoiceDataSource}
        bordered
        // scroll={{ x: 1300 }}
        pagination={false}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  className="awb-table-currency"
                  colSpan={8}
                  index={1}
                >
                  Tổng: {totalGross}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <hr />
      <div>
        <h2>Payment details</h2>
      </div>
      <Form className="form-einvoice-payment" layout="vertical" form={form}>
        <Row gutter={[5, 0]}>
          <Col span={24} md={3} xl={3}>
            <Form.Item name="cash" label="Cash amt">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={3} xl={3}>
            <Form.Item name="credit" label="Credit Amt">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={4} xl={4}>
            <Form.Item name="net" label="Net amt in VND">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={4} xl={4}>
            <Form.Item name="vat" label="Total VAT">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={4} xl={4}>
            <Form.Item name="total" label="Total Amt. VND">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label="Remark">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default TabEInvoice;
