/* eslint-disable */
import React, { useEffect } from "react";
import { Col, Input, Row, Form, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setMAWB } from "../../../actions/MAWB";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { logoutCheckin } from "../../../config/checkinApi";
import { setPaymentInfo } from "./../../../actions/payment";
const AccountInfo = ({ dataLogin }) => {
  const [form] = useForm();
  const history = useHistory();
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const { dataCheckin } = useSelector((state) => state.userAwb);
  const dispatch = useDispatch();

  const handleLogoutCheckin = async () => {
    try {
      await logoutCheckin(token);
      dispatch(checkinUser({}));
      dispatch(setPaymentInfo(undefined));
      dispatch(userAwbLogout());
      localStorage.removeItem("userAWB");
      localStorage.removeItem("checkin");
      localStorage.removeItem("pInfo");
      history.push("/");
      notification.success({
        message: "Đăng xuất thành công!",
        style: { marginRight: "-50%" },
      });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (dataCheckin !== undefined) {
      form.setFieldsValue({
        mawb: dataCheckin.AWB,
        hawb: dataCheckin.COD_HWB_NUM,
        pcs: dataCheckin.CARGOPIECE,
        weight: dataCheckin.CARGOWEIGHT,
        status: dataCheckin.COD_STS,
        flightNo: dataCheckin.FLIGHTNUMBER,
        flightDate: moment(dataCheckin.FLIGHTDATE).format("DD MMM YYYY"),
        location: dataCheckin.CONTAINER_LOC,
        description: dataCheckin.COMMODITY_DES,
        dateCre: moment(dataCheckin.CREATEDATE).format("DD MMM YYYY"),
        nsw: dataCheckin.CIMP,
      });
    }
  }, [dataCheckin]);

  return (
    <Row style={{ flexDirection: "column" }} className="login-form-wrapper">
      <Row className="login-form-title-wrapper">
        <Col className="login-form-title" offset={4}>
          <h2>Thông tin tài khoản</h2>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", display: "flex" }}>
        <Col style={{ margin: "0 auto" }} span={18}>
          <Form form={form} className="account-info" layout="vertical">
            <Row gutter={[5, 0]}>
              <Col span={24} md={4} xl={4}>
                <Form.Item name="mawb" label="MAWB">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={4} xl={4}>
                <Form.Item name="hawb" label="HAWB">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={3} xl={3}>
                <Form.Item name="pcs" label="PCS">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={3} xl={3}>
                <Form.Item name="weight" label="Weight">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={2} xl={2}>
                <Form.Item name="status" label="Status">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={4} xl={4}>
                <Form.Item name="flightNo" label="Flight No">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={4} xl={4}>
                <Form.Item name="flightDate" label="Flight Date">
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[5, 0]}>
              <Col span={24} md={2} xl={2}>
                <Form.Item name="location" label="Location">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={9} xl={9}>
                <Form.Item name="description" label="Commodity Description">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={5} xl={5}>
                <Form.Item name="dateCre" label="Date Cre">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={8} xl={8}>
                <Form.Item name="nsw" label="Irr Info">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                span={12}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    dispatch(
                      setMAWB({
                        first: dataCheckin.COD_AWB_PFX,
                        last: dataCheckin.COD_AWB_NUM,
                        suffix: dataCheckin.COD_AWB_SFX,
                        hawb: dataCheckin.COD_HWB_NUM,
                      })
                    );
                    localStorage.setItem(
                      "MAWB",
                      JSON.stringify({
                        first: dataCheckin.COD_AWB_PFX,
                        last: dataCheckin.COD_AWB_NUM,
                        suffix: dataCheckin.COD_AWB_SFX,
                        hawb: dataCheckin.COD_HWB_NUM,
                      })
                    );
                    history.push("/awb/checkout");
                  }}
                >
                  {/* {t("header.submit")} */} Tiếp tục
                </Button>
              </Col>
              <Col
                span={12}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content" }}
                  onClick={handleLogoutCheckin}
                >
                  {/* {t("header.submit")} */} Đăng xuất
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};
export default AccountInfo;
