import React, { useEffect, useState } from 'react'
import { Form, Checkbox, Row, Col, Input, Typography } from 'antd'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { TempSelect } from './TempSelect';
// import { EsidStatusEnum } from '../../../../../constant/enumerations';
const {TextArea} = Input;
export const HandlingInfomationForm = ({name, form, readOnly}) => {
  const { t } = useTranslation("common");
  const [isOthRqsActive, setIsOthRqsActive] = useState(false);
  const [isTmpStrActive, setIsTmpStrActive] = useState(false);
  const [isTempSelectOpen, setIsTempSelectOpen] = useState(false);
  const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
  const options = [
    { label: t("formInfo.perishable"), value: 'shcPer' },
    { label: t("formInfo.Pharmaceutical"), value: 'shcOth002' },
    { label: t("formInfo.valuable"), value: 'shcVal' },
    { label: t("formInfo.liveAnimal"), value: 'shcAvi' },
    { label: t("formInfo.DangerousGoods"), value: 'shcDgr' },
    { label: t("formInfo.shcBup"), value: 'shcBup' },
    { label: t("formInfo.humanRemains"), value: 'shcHum' },
    { label: t("formInfo.diplomatic"), value: 'shcDip' },
  ];
  useEffect(() => {
    setIsOthRqsActive(false);
    setIsTmpStrActive(false);
    if ( selectEsidExport && selectEsidExport.shcColStr === 'Y') {
      setIsTmpStrActive(true);
    }
    if ( selectEsidExport && selectEsidExport.shcOth001 === 'Y') {
      setIsOthRqsActive(true);
    }
  },[selectEsidExport])
  return (
      <Form
        name={name}
        labelAlign='left'
        labelCol={{span: 24}}
        form={form}
        style={{padding: 10}}
        className='iata-form'
        autoComplete="off"
      >
      <Row gutter={10}>
        <Col lg={24} xs={24}>
          <Form.Item
              label={<Typography.Text strong>{t('admin.esid.handlingInfoTitle')}</Typography.Text>}
              name="handlingInfomations"
              initialValue={[]}
            >
              <Checkbox.Group
                style={{ width: '100%'}}
                disabled={readOnly}
              >
                <Row gutter={[5,5]}>
                  {
                    options && options.map((option) => (
                      <Col key={option.value} xs={12} sm={12} md={10} xl={8} xxl={4}>
                        <Checkbox value={option.value}>{option.label}</Checkbox>
                      </Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={24} style={{ fontWeight: 'bold' }}>
          {t("formInfo.vulnerable")}
        </Col>
        <Col xs={12} sm={12} md={10} xl={8} xxl={4} style={{ paddingLeft: 30 }}>
          <Form.Item
            name="shcVunNml"
            noStyle
            valuePropName="checked"
          >
            <Checkbox>{t("formInfo.regular")}</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={10} xl={8} xxl={4}>
          <Form.Item
            name="shcEcort"
            noStyle
            valuePropName="checked"
          >
            <Checkbox>{t("formInfo.escort")}</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={10} xl={8} xxl={4}>
          <Form.Item
            name="shcReinforce"
            noStyle
            valuePropName="checked"
          >
            <Checkbox>{t("formInfo.reinforced")}</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginTop: 8}}>
          <Form.Item
            name="shcOth"
            valuePropName="checked"
            style={{ maxHeight: 20 }}
          >
            <Checkbox >
              {t("formInfo.other")}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Row gutter={[0,10]} style={{marginTop: 10, marginLeft: 0, marginRight: 0}}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name="shcColStr"
                valuePropName="checked"
              >
                <Checkbox disabled={readOnly} onChange={(e) => setIsTmpStrActive(e.target.checked)}>
                  {t("formInfo.tempStorage")}
                </Checkbox>
              </Form.Item>
              <Form.Item 
                label={
                  <Typography.Text style={{fontSize: 14}}>
                    {`${t("formInfo.tempRequest")} (°C)`}
                  </Typography.Text>
                }
                style={{ marginTop: -10 }}
                >
                <Input.Group compact>
                {
                  readOnly ? (
                    <>
                      <Form.Item 
                        noStyle
                        name="shcColTemReq" 
                        rules={[
                          {
                            required: isTmpStrActive,
                            message: 'required'
                          }
                        ]}
                        >
                        <Input style={{ width: '30%' }} readOnly/>
                      </Form.Item>
                      <Form.Item 
                        name="shcColTemReqString" 
                        noStyle
                      >
                        <Input style={{ width: '70%' }} readOnly/>
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item 
                        noStyle
                        name="shcColTemReq" 
                        rules={[
                          {
                            required: isTmpStrActive,
                            message: t("admin.validation.required"),
                          }
                        ]}
                        >
                        <TempSelect form={form} isSelectOpen={isTempSelectOpen} setIsSelectOpen={setIsTempSelectOpen} />
                      </Form.Item>
                      {
                        !isTempSelectOpen &&
                          <Form.Item 
                            name="shcColTemReqString" 
                            noStyle
                          >
                            <Input style={{ width: '70%' }} readOnly/>
                          </Form.Item>
                      }
                    </>
                  )
                }  
                </Input.Group>
              </Form.Item>
            </Col>

            <Col md={12} sm={24} xs={24} style={{ display: 'flex', alignItems: 'flex-end'}}>
              <Row style={{ width: '100%' }}>
                <Col span={24} style={{ marginBottom: 3}}>
                  <Form.Item
                    name="shcOth001"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={readOnly} onChange={(e) => setIsOthRqsActive(e.target.checked)}>
                      {t("formInfo.otherRequest")}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="shcOthReq"
                  >
                    <TextArea rows={1} readOnly={readOnly} disabled={!isOthRqsActive} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item
                name="shcCod002"
                valuePropName="checked"
              >
                <Checkbox style={{ fontWeight: 'bold', fontSize: 16 }}>
                  {t("Kho hàng TECS")}
                </Checkbox>
              </Form.Item>
            </Col>

          </Row>
        </Col>
      </Row>
    </Form>
  )
}
