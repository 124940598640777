/* eslint-disable */
import React, { useRef, useState } from "react";
import { Row, Col, Button, Input, Form, Table, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import { setPaymentInfo } from "../../../actions/payment";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";

import { GET_EXP_SHPMENTS_IN_WH } from "../../../config/webTrackApi";

const TabAirlinesExpInven = () => {
  const { t } = useTranslation("common");
  const [form] = useForm();
  const { dataLogin } = useSelector((state) => state.userAwb);
  const { signedIn } = useSelector((state) => state.userAwb);
  const [headerTable, setHeaderTable] = useState();
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const submitGoodsRepo = async (values) => {
    const flightDate = moment().format("DD/MM/YYYY");
    let { carrierId, flightNumber } = values;
    if (carrierId === undefined)
      carrierId = dataLogin.data.user_code.slice(
        2,
        dataLogin.data.user_code.length
      );
    try {
      setLoading(true);
      let dataRes =
        dataLogin.data.user_code.includes("admi") ||
          dataLogin.data.user_code.includes(carrierId)
          ? await GET_EXP_SHPMENTS_IN_WH(
            carrierId,
            flightNumber,
            flightDate,
            dataLogin.token
          )
          : notification["error"]({
            message: "INFO",
            description: t("agentReport.role"),
            style: { marginRight: "-50%" },
          });
      let data =
        dataRes &&
        dataRes.data.mCol.map((dt, index, array) => {
          return index === 0
            ? { ...dt }
            : {
              ...dt,
              WH_ULD_NO:
                array[index - 1].WH_ULD_NO === dt.WH_ULD_NO
                  ? ""
                  : dt.WH_ULD_NO,
            };
        });
      setDataSource(data);
      setHeaderTable(t("agentReport.carrier") + ` ${flightNumber} AIR`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDataSource(undefined);
      if (error.response && error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification["error"]({
          message: "INFO",
          description: t("agentReport.notfindData"),
          style: { marginRight: "-50%" },
        });
    }
  };

  const columns = [
    {
      title: t("agentReport.no"),
      dataIndex: "FLT_Index",
      key: "FLT_Index",
      className: "head_tb_repo",
      render: (_, record, index) => index + 1,
    },
    {
      title: t("agentReport.fightNo"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.noflight"),
          dataIndex: "STR_FLT_NO",
          key: "STR_FLT_NO",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.Date"),
          dataIndex: "STR_FLT_DAT",
          key: "STR_FLT_DAT",
          className: "head_tb_repo",
          render: (text) =>
            moment(text).isValid() &&
              moment(text).format("DD/MM/YYYY") === "01/01/2001"
              ? ""
              : moment(text).isValid() && moment(text).format("DD/MM/YYYY"),
        },
      ],
    },
    {
      title: "ULD",
      dataIndex: "WH_ULD_NO",
      key: "WH_ULD_NO",
      className: "head_tb_repo",
      width: "12%",
    },
    {
      title: t("agentReport.Contour"),
      dataIndex: "CONTOUR",
      key: "CONTOUR",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.MAWB"),
      dataIndex: "WH_AWB_NO",
      key: "WH_AWB_NO",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.totalPieces"),
      dataIndex: "QTY_SHP_QTY_EPM_PCS",
      key: "QTY_SHP_QTY_EPM_PCS",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.Pieces"),
      dataIndex: "QTY_EPM_PCS",
      key: "QTY_EPM_PCS",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.GrossWeight"),
      dataIndex: "QTY_EPM_WGT",
      key: "QTY_EPM_WGT",
      className: "head_tb_repo",
      render: (text) => (text === "0" ? "" : text),
    },
    {
      title: t("agentReport.from"),
      dataIndex: "COD_FDS",
      key: "COD_FDS",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.bookingFlight"),
      dataIndex: "parent",
      key: "parent",
      className: "head_tb_repo",
      children: [
        {
          title: t("agentReport.noflight"),
          dataIndex: "ORI_FLT_NO",
          key: "ORI_FLT_NO",
          className: "head_tb_repo",
        },
        {
          title: t("agentReport.Date"),
          dataIndex: "ORI_FLT_DAT",
          key: "ORI_FLT_DAT",
          className: "head_tb_repo",
          render: (text) =>
            moment(text).isValid() &&
              moment(text).format("DD/MM/YYYY") === "01/01/2001"
              ? ""
              : moment(text).isValid() && moment(text).format("DD/MM/YYYY"),
        },
      ],
    },
    {
      title: "SHC",
      dataIndex: "SERVICE_REQUEST",
      key: "SERVICE_REQUEST",
      className: "head_tb_repo",
    },
    {
      title: t("agentReport.status"),
      dataIndex: "EPM_STS",
      key: "EPM_STS",
      className: "head_tb_repo",
    },
  ];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const user = signedIn && dataLogin.data.user_code.slice(2, 4);
  return (
    <div>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("agentReport.listMAWB")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row gutter={[0, 16]} className="control-wrapper">
          <Col
            offset={dataLogin.data.user_code === "admi" ? 5 : 8}
            span={20}
            className="control"
          >
            <Form
              className="form-status"
              layout={"inline"}
              form={form}
              requiredMark="optional"
              onFinish={submitGoodsRepo}
              initialValues={{
                flightNumber: `${user}`,
              }}
              autoComplete="off"
            >
              {dataLogin.data.user_code === "admi" && (
                <Form.Item
                  name="carrierId"
                  label={t("agentReport.DeliveryTranform")}
                  rules={[
                    { required: true, message: t("agentReport.require") },
                  ]}
                >
                  <Input className="flight-number" />
                </Form.Item>
              )}
              <Form.Item
                name="flightNumber"
                label={t("agentReport.noflight")}
                rules={[{ required: true, message: t("agentReport.require") }]}
              >
                <Input className="flight-number" />
              </Form.Item>
              {/* <Form.Item
                name="flightDate"
                label="Ngày: "
                rules={[{ required: true, message: "Cần nhập" }]}
              >
                <DatePicker className="flight-number" format="DD/MM/YYYY" />
              </Form.Item> */}
              <Row className="control-button-group-wrapper">
                <Col offset={dataLogin.data.user_code === "admi" ? 5 : 2}>
                  <Button
                    style={{ width: "fit-content" }}
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("agentReport.refresh")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={handlePrint}
                    style={{ width: "fit-content" }}
                    disabled={!dataSource}
                  >
                    {t("agentReport.print")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row ref={componentRef} className="result-wrapper">
          <Row className="result-wrapper">
            <Row className="result-title-wrapper">
              <Col span={24} className="result-title">
                <h5>{t("header.list")}</h5>
              </Col>
            </Row>
            <Row className="result-wrapper">
              <Col span={24} className="result">
                {dataSource && (
                  <Table
                    className="table-bill"
                    dataSource={dataSource}
                    columns={columns}
                    title={() => headerTable}
                    bordered
                    scroll={{ x: 500 }}
                    pagination={{
                      position: "bottomRight",
                      defaultPageSize: 1000,
                    }}
                    summary={() => (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={16}>
                            {t("agentReport.total")} {dataSource.length}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3}>
                            {t("agentReport.speechStatus")} 1
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={0} colSpan={13}>
                            <strong>ACPT:</strong> {t("agentReport.get")} LBXR:{" "}
                            {t("agentReport.speechStatus")}
                            <strong>SCR:</strong>{" "}
                            {t("agentReport.didCheckDelivery")}
                            <strong>LOD:</strong> {t("agentReport.getflight")}{" "}
                            <strong>DON:</strong> {t("agentReport.finish")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={16}>
                            {t("agentReport.Time")}{" "}
                            <Moment format="DD/MM/YYYY hh-mm">{new Date()}</Moment>
                            {/* {moment().format("DD/MM/YYYY HH:MM:SS")} */}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )}
                  />
                )}
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
    </div>
  );
};

export default TabAirlinesExpInven;
