/* eslint-disable */
import React from "react";
import { Col, Descriptions, Row, Skeleton, Collapse } from "antd";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setHAWB, setMAWB } from "../../actions/MAWB";
import { setPaymentInfo } from "../../actions/payment";
import { checkinUser } from "../../actions/userAWB";
import {
  GET_HAWB_CTC_INF,
  GET_HAWB_SHIPMENT_INF,
} from "../../config/webTrackApi";
import ShipmentInfo from "../ShipmentInfo";
import { userAwbLogout } from './../../actions/userAWB';
const { Panel } = Collapse;

const ImportResultWithDetails = (props) => {
  const [HAWBCTCInfo, setHAWBCTCInfo] = useState();
  const [titleShipmentInfo, setTitleShipmentInfo] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { MAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataLogin);
  const [columnsTableShipment, setColumnsTableShipment] = useState([]);
  const [dataShipmentInfo, setDataShipmentInfo] = useState([]);
  const showInfo = (hawbNo) => {
    try {
      if (hawbNo !== undefined) {
        dispatch(setMAWB({ ...MAWB, hawb: hawbNo }));
        dispatch(setHAWB(hawbNo));
        GET_HAWB_CTC_INF(
          props.firstParam,
          props.secondParam,
          hawbNo,
          token
        ).then((res) => {
          if (res.status == 200 && res.data != "" && res.data.mCol.length)
            setHAWBCTCInfo(res.data.mCol[0]);
        });
        GET_HAWB_SHIPMENT_INF(
          props.firstParam,
          props.secondParam,
          hawbNo,
          token
        ).then((res) => {
          if (res.status == 200 && res.data != "" && res.data.mCol.length) {
            setTitleShipmentInfo(
              res.data.mCol.length === 0
                ? "Thông tin vận chuyển: Chưa có hàng"
                : "Thông tin vận chuyển"
            );
            const columns = [
              [
                {
                  className: "head-awb",
                  title: "Số hiệu chuyến bay",
                  dataIndex: "H09_COD_HAWB_CAR",
                  key: "H09_COD_HAWB_CAR",
                },
                {
                  className: "head-awb",
                  title: "Giờ đến",
                  dataIndex: "H17_FLT_ATA",
                  key: "H17_FLT_ATA",
                },
                {
                  className: "head-awb",
                  title: "Số kiện",
                  dataIndex: "H05_QTY_HAWB_PCS",
                  key: "H05_QTY_HAWB_PCS",
                },
                {
                  className: "head-awb",
                  title: "Trọng lượng",
                  dataIndex: "H06_QTY_HAWB_WGT",
                  key: "H06_QTY_HAWB_WGT",
                },
                {
                  className: "head-awb",
                  title: "Trọng lượng cân lại (nếu có)",
                  dataIndex: "H18_HAWB_RE_WGT",
                  key: "H18_HAWB_RE_WGT",
                },
                {
                  className: "head-awb",
                  title: "Tình trạng",
                  dataIndex: "H20_GOODS_ST",
                  key: "H20_GOODS_ST",
                },
              ],
              [
                {
                  className: "head-awb",
                  title: "Lần",
                  dataIndex: "index",
                  key: "index",
                },
                {
                  className: "head-awb",
                  title: "Số hiệu chuyến bay",
                  dataIndex: "H09_COD_HAWB_CAR",
                  key: "H09_COD_HAWB_CAR",
                },
                {
                  className: "head-awb",
                  title: "Giờ đến",
                  dataIndex: "H17_FLT_ATA",
                  key: "H17_FLT_ATA",
                },
                {
                  className: "head-awb",
                  title: "Số kiện",
                  dataIndex: "H05_QTY_HAWB_PCS",
                  key: "H05_QTY_HAWB_PCS",
                },
                {
                  className: "head-awb",
                  title: "Trọng lượng",
                  dataIndex: "H06_QTY_HAWB_WGT",
                  key: "H06_QTY_HAWB_WGT",
                },
                {
                  className: "head-awb",
                  title: "Trọng lượng cân lại (nếu có)",
                  dataIndex: "H18_HAWB_RE_WGT",
                  key: "H18_HAWB_RE_WGT",
                },
                {
                  className: "head-awb",
                  title: "Tình trạng",
                  dataIndex: "H20_GOODS_ST",
                  key: "H20_GOODS_ST",
                },
              ],
            ];
            if (res.data.mCol.length !== 1) {
              let data = [];
              let goodsStatus = "";

              res.data.mCol.map((dt, index) => {
                if (
                  (dt.H21_CHK_IN_DATE == "" &&
                    dt.H04_FLG_HWB_RDY == "Y" &&
                    dt.H20_GOODS_ST != "DLV" &&
                    (dt.H30_NUM_NOTIFY == "0" || dt.H30_NUM_NOTIFY == "")) ||
                  (dt.H21_CHK_IN_DATE == "" &&
                    dt.H20_GOODS_ST == "RCF" &&
                    dt.H30_NUM_NOTIFY == "0")
                )
                  goodsStatus = `Hàng đã được nhập kho TCS`;
                else if (
                  dt.H21_CHK_IN_DATE == "" &&
                  dt.H04_FLG_HWB_RDY == "Y" &&
                  dt.H20_GOODS_ST != "DLV" &&
                  (dt.H30_NUM_NOTIFY !== "" || dt.H30_NUM_NOTIFY !== "0")
                )
                  goodsStatus = `Hàng đã được thông báo lần ${dt.H30_NUM_NOTIFY}`;
                else if (
                  (dt.H21_CHK_IN_DATE != "" && dt.H20_GOODS_ST != "DLV") ||
                  dt.H20_GOODS_ST == "AR"
                )
                  goodsStatus = "Hàng đã được làm thủ tục, chưa lĩnh hàng";
                else if (dt.H20_GOODS_ST == "DLV")
                  goodsStatus = `Hàng đã được giao cho: ${dt.H23_RCV_NAME}  ngày ${dt.H22_DLV_DATE}`;
                else
                  goodsStatus =
                    "Liên hệ TCS để biết thêm thông tin về lô hàng này";
                data.push({
                  key: index,
                  index: ++index,
                  H06_QTY_HAWB_WGT: dt.H06_QTY_HAWB_WGT + "(kg)",
                  H18_HAWB_RE_WGT:
                    dt.H18_HAWB_RE_WGT === ""
                      ? ""
                      : dt.H18_HAWB_RE_WGT + "(kg)",

                  H05_QTY_HAWB_PCS: dt.H05_QTY_HAWB_PCS,
                  H09_COD_HAWB_CAR: dt.H09_COD_HAWB_CAR + dt.H10_COD_HAWB_FLT,
                  H17_FLT_ATA: moment(dt.H17_FLT_ATA).format("LL HH:mm"),
                  H20_GOODS_ST: goodsStatus,
                  H03_COD_AWB_SFX: dt.H03_COD_AWB_SFX,
                });
              });
              setDataShipmentInfo(data);
              setColumnsTableShipment(columns[1]);
            } else {
              let data = [];
              let goodsStatus = "";

              res.data.mCol.map((dt, index) => {
                if (
                  (dt.H21_CHK_IN_DATE == "" &&
                    dt.H04_FLG_HWB_RDY == "Y" &&
                    dt.H20_GOODS_ST != "DLV" &&
                    (dt.H30_NUM_NOTIFY == "0" || dt.H30_NUM_NOTIFY == "")) ||
                  (dt.H21_CHK_IN_DATE == "" &&
                    dt.H20_GOODS_ST == "RCF" &&
                    dt.H30_NUM_NOTIFY == "0")
                )
                  goodsStatus = `Hàng đã được nhập kho TCS`;
                else if (
                  dt.H21_CHK_IN_DATE == "" &&
                  dt.H04_FLG_HWB_RDY == "Y" &&
                  dt.H20_GOODS_ST != "DLV" &&
                  (dt.H30_NUM_NOTIFY !== "" || dt.H30_NUM_NOTIFY !== "0")
                )
                  goodsStatus = `Hàng đã được thông báo lần ${dt.H30_NUM_NOTIFY}`;
                else if (
                  (dt.H21_CHK_IN_DATE != "" && dt.H20_GOODS_ST != "DLV") ||
                  dt.H20_GOODS_ST == "AR"
                )
                  goodsStatus = "Hàng đã được làm thủ tục, chưa lĩnh hàng";
                else if (dt.H20_GOODS_ST == "DLV")
                  goodsStatus = `Hàng đã được giao cho: ${dt.H23_RCV_NAME}  ngày ${dt.H22_DLV_DATE}`;
                else
                  goodsStatus =
                    "Liên hệ TCS để biết thêm thông tin về lô hàng này";
                data.push({
                  key: index,
                  H06_QTY_HAWB_WGT: dt.H06_QTY_HAWB_WGT + "(kg)",
                  H18_HAWB_RE_WGT:
                    dt.H18_HAWB_RE_WGT === ""
                      ? ""
                      : dt.H18_HAWB_RE_WGT + "(kg)",
                  H05_QTY_HAWB_PCS: dt.H05_QTY_HAWB_PCS,
                  H09_COD_HAWB_CAR: dt.H09_COD_HAWB_CAR + dt.H10_COD_HAWB_FLT,
                  H17_FLT_ATA: moment(dt.H17_FLT_ATA).format("LL HH:mm"),
                  H20_GOODS_ST: goodsStatus,
                  H03_COD_AWB_SFX: dt.H03_COD_AWB_SFX,
                });
              });
              setDataShipmentInfo(data);
              setColumnsTableShipment(columns[0]);
            }
          }
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  return (
    <>
      <Row className="result-content-wrapper">
        <Col className="awb-result-content" span={24}>
          <Collapse onChange={showInfo} accordion>
            {props.HAWBs &&
              props.HAWBs.map((HAWB) => {
                let header = "";
                props.HAWBs.length !== 1
                  ? (header = `Thông tin lô hàng có Số Hawb: ${HAWB.H25_HAWB_NO} (Số kiện: ${HAWB.H05_QTY_HAWB_PCS}, Trọng lượng: ${HAWB.H06_QTY_HAWB_WGT})`)
                  : (header = `Thông tin lô hàng có Số Hawb: ${HAWB.H25_HAWB_NO}`);
                return (
                  <Panel
                    className="hawb-title"
                    header={header}
                    key={HAWB.H25_HAWB_NO}
                  >
                    <Row className="result-content-wrapper">
                      <Col className="awb-result-content" span={24}>
                        <Skeleton loading={props.loading}>
                          <Descriptions
                            className="hawb-result"
                            bordered
                            size={"small"}
                          >
                            <Descriptions.Item label="Số kiện hàng">
                              {`${HAWB.H05_QTY_HAWB_PCS}`}
                            </Descriptions.Item>
                          </Descriptions>
                          <Descriptions
                            className="hawb-result"
                            bordered
                            size={"small"}
                          >
                            <Descriptions.Item label="Trọng lượng">
                              {`${HAWB.H06_QTY_HAWB_WGT} (kg)`}
                            </Descriptions.Item>
                          </Descriptions>
                        </Skeleton>
                      </Col>
                    </Row>
                    <Row className="result-title-wrapper">
                      <Col span={24} className="awb-result-title">
                        <h5>Thông tin lô hàng</h5>
                      </Col>
                    </Row>
                    <Row className="result-content-wrapper">
                      <Col className="awb-result-content" span={24}>
                        <Skeleton loading={props.loading}>
                          <Descriptions
                            className="hawb-result"
                            bordered
                            size={"small"}
                          >
                            <Descriptions.Item label="Người gởi">
                              {HAWBCTCInfo && `${HAWBCTCInfo.C03_SHP_NAME}`}
                            </Descriptions.Item>
                          </Descriptions>
                          <Descriptions
                            className="hawb-result"
                            bordered
                            size={"small"}
                          >
                            <Descriptions.Item label="Địa chỉ">
                              {HAWBCTCInfo && `${HAWBCTCInfo.C04_SHP_ADDR}`}
                            </Descriptions.Item>
                          </Descriptions>
                          <Descriptions
                            className="hawb-result"
                            bordered
                            size={"small"}
                          >
                            <Descriptions.Item label="Người nhận">
                              {HAWBCTCInfo && `${HAWBCTCInfo.C01_CNE_NAME}`}
                            </Descriptions.Item>
                          </Descriptions>
                          <Descriptions
                            className="hawb-result"
                            bordered
                            size={"small"}
                          >
                            <Descriptions.Item label="Địa chỉ">
                              {HAWBCTCInfo && `${HAWBCTCInfo.C02_CNE_ADDR}`}
                            </Descriptions.Item>
                          </Descriptions>
                        </Skeleton>
                      </Col>
                    </Row>
                    <ShipmentInfo
                      loading={props.loading}
                      titleShipmentInfo={titleShipmentInfo}
                      dataShipmentInfo={dataShipmentInfo}
                      columnsTableShipment={columnsTableShipment}
                      setIsRowSelected={props.setIsRowSelected}
                    />
                  </Panel>
                );
              })}
          </Collapse>
        </Col>
      </Row>
    </>
  );
};
export default ImportResultWithDetails;
