import { Button, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import { setPaymentInfo } from "../../actions/payment";
import { checkinUser } from "../../actions/userAWB";
import { GET_LETTER_INF } from "../../config/webTrackApi";
import tcs from "../../image/logo.png";
import { userAwbLogout } from "./../../actions/userAWB";

const Letter = ({ AWB, setRenderLetter }) => {
  const [data, setData] = useState({});
  const letterRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useSelector((state) => state.userAwb.dataLogin);
  const handlePrint = useReactToPrint({
    content: () => letterRef.current,
  });
  const { t } = useTranslation("common");
  const getLetter = async () => {
    try {
      await GET_LETTER_INF(AWB.MAWB, AWB.HAWB, token).then((res) =>
        setData(res.data.mCol[0])
      );
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  useEffect(() => {
    getLetter();
    // eslint-disable-next-line
  }, [AWB.HAWB, AWB.MAWB]);
  return (
    <React.Fragment>
      <div ref={letterRef} style={{ minWidth: "1080px" }}>
        <div
          className="letter-table"
          style={{
            width: "800px",
            margin: "20px auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <table>
                <tbody>
                  <tr>
                    <td rowspan="5">
                      <img height={80} width={125} src={tcs} alt="Sign Email" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("letter.tcs")}</td>
                  </tr>
                  <tr>
                    <td>{t("letter.addressCom")}</td>
                  </tr>
                  <tr>
                    <td>{t("letter.phone")}</td>
                  </tr>
                  <tr>
                    <td>website: www.tcs.com.vn</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table>
                <tbody>
                  <tr>
                    <td>{t("letter.no")}</td>
                    <td>{data.L01_SO_THU_BAO}</td>
                  </tr>
                  <tr>
                    <td>{t("letter.printDate")}</td>
                    <td>{data.L02_NGAY_IN_THU}</td>
                  </tr>
                  <tr>
                    <td>{t("letter.countReceive")}</td>
                    <td>{data.L03_LAN_PHAT_THU}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <table
              style={{
                width: "100%",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <tbody>
                <tr>
                  <td
                    align="center"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    {t("letter.NoticeReceive")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      width: "230px",
                    }}
                  >
                    {t("letter.receive")}
                  </td>
                  <td>{data.L04_TEN_KHACH_HANG}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>{t("letter.add")}</td>
                  <td>{data.L05_DIA_CHI_KHACH_HANG}</td>
                </tr>
                <tr>
                  <td colSpan="4">&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan="4">{t("letter.invite")}</td>
                </tr>
                <tr>
                  <td>{t("letter.dayReceive")}</td>
                  <td>{t("letter.timeReceive")}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td colSpan="3">{t("letter.weedkend")}</td>
                </tr>
                <tr>
                  <td>{t("letter.mawb")}</td>
                  <td>{data.L06_SO_MAWB}</td>
                  <td style={{ width: "20%" }}>{t("letter.subMawb")}</td>
                  <td style={{ width: "10%" }}>{data.L07_SO_HAWB}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div style={{ width: "10px" }}>&nbsp;</div>
          <h2>{t("letter.infoGross")}</h2>
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td className="title">{t("letter.fightNo")}</td>
                  <td className="comma">:</td>
                  <td className="data">{data.L08_CHUYEN_BAY_SO}</td>
                  <td>
                    {t("letter.Date")} {data.L09_CHUYEN_BAY_NGAY}
                  </td>
                </tr>
                <tr>
                  <td className="comma">{t("letter.Pieces")}</td>
                  <td className="title">: </td>
                  <td className="data">{data.L10_SO_KIEN}</td>
                </tr>
                <tr>
                  <td className="comma">{t("letter.GrossWeight")}</td>
                  <td className="title">: </td>
                  <td className="data">{data.L11_TRONG_LUONG}</td>
                </tr>
                <tr>
                  <td className="comma">{t("letter.NatureGoods")}</td>
                  <td className="title">:</td>
                  <td className="data">{data.L12_LOAI_HANG}</td>
                </tr>
                <tr>
                  <td className="comma">{t("letter.sender")}</td>
                  <td className="title">: </td>
                  <td className="data">{data.L13_NGUOI_GUI_TEN}</td>
                </tr>
                <tr>
                  <td className="comma">{t("letter.addressSender")}</td>
                  <td className="title">:</td>
                  <td className="data">{data.L14_NGUOI_GUI_DIA_CHI}</td>
                </tr>
                <tr>
                  <td className="comma">{t("letter.CargoStatus")}</td>
                  <td className="title">:</td>
                  <td className="data">{data.L15_TINH_TRANG_LO_HANG}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div style={{ width: "10px" }}>&nbsp;</div>
          <div>
            <h3 style={{ fontSize: "20px" }}>{t("letter.needDocument")}</h3>
            <table
              style={{
                width: "100%",
                marginLeft: "50px",
                maxWidth: "750px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold" }}>{t("letter.caseA")}</td>
                </tr>
                <tr>
                  <td>
                    <ol>
                      <li>{t("letter.noticeReceive")}</li>
                      <li>{t("letter.introLetter")}</li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>{t("letter.caseB")}</td>
                </tr>
                <tr>
                  <td>
                    <ol>
                      <li>{t("letter.permanmentResidence")}</li>
                      <li>{t("letter.id")}</li>
                      <li>{t("letter.delegacy")}</li>
                      <ul>
                        <li>{t("letter.delegacyPaper")}</li>
                        <li>{t("letter.authorize")}</li>
                      </ul>
                      <li>{t("letter.staying")}</li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div style={{ width: "10px" }}>&nbsp;</div>
          <div>
            <h3 style={{ fontSize: "20px" }}>{t("letter.earnRegiter")}</h3>
            <table
              style={{
                width: "100%",
                marginLeft: "50px",
                maxWidth: "750px",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    {t("letter.service")}
                    <ul>
                      <li>Tel: (028) 38486490-96 / EXT: 141</li>
                      <li>Fax: (028) 38427943</li>
                      <li>Email: import@tcs.com.vn</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="awb-tab-content">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => handlePrint()}
            style={{ width: "fit-content" }}
          >
            {t("airlines.print")}
          </Button>
          <Button
            onClick={() => setRenderLetter(false)}
            style={{ width: "fit-content" }}
          >
            {t("letter.back")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Letter;
