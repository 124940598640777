/* eslint-disable */

import {
  Col,
  notification,
  Row,
  Table,
  Skeleton,
  Descriptions,
  Button,
  DatePicker,
  Select,
  Form,
} from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./component-css/ChargeTab.css";
import {
  CALC_CHARGE,
  GetBillDetail,
  GET_CHARGE_CODE_CS,
  GET_CHARGE_INF,
  GET_CHARGE_INF_CS,
  GET_CHG_DTL_WEB,
  GET_EXP_ACCEPTANCE,
  GET_EXP_BOOKING_INF,
  GET_HAWB_DOC_INF,
  GET_HAWB_LOCATION,
  GET_HAWB_SHIPMENT_INF,
  GET_IMP_FLIGHT_INF,
  GET_MAWB_LOCATION,
  GET_SHIPMENT_INF,
} from "../config/webTrackApi";
import { setHAWB } from "../actions/MAWB";
import { useTranslation } from "react-i18next";
import Parser from "html-react-parser";
import React from "react";
import ChargeInfoControls from "./ChargeInfoControls";
import cryptoRandomString from "crypto-random-string";
import { checkinUser, userAwbLogout } from "../actions/userAWB";
import { setPaymentInfo } from "../actions/payment";
import { useHistory } from "react-router";
const { Option } = Select;
const { useForm } = Form;

const ChargesTab = (props) => {
  const dispatch = useDispatch();
  const { langId } = useSelector((state) => state.menu);
  const { billStatus, billType } = props;
  const [form] = useForm();
  let sessionId = cryptoRandomString({ length: 40 });
  const [flightNo, setFlightNo] = useState();
  const [shipments, setShipments] = useState([]);
  const [mawbLocation, setMawbLocation] = useState();
  const [hawbLocation, setHawbLocation] = useState();
  const [storeType, setStoreType] = useState();
  const [serviceType, setServiceType] = useState();
  const [dateBegin, setDateBegin] = useState();
  const [totalSunday, setTotalSunday] = useState(0);
  const [totalHoliday, setTotalHoliday] = useState(0);
  const [totalFreeDay, setTotalFreeDay] = useState(0);
  const [totalDayCharge, setTotalDayCharge] = useState(0);
  const [multiStore, setMultiStore] = useState();
  const [isExpress, setIsExpress] = useState();
  const [impATA, setImpATA] = useState();
  const [detailStoreCharge, setDetailStoreCharge] = useState();
  const [detailServiceCharge, setDetailServiceCharge] = useState();
  const [storeVatRate, setStoreVatRate] = useState();
  const [serviceVatRate, setServiceVatRate] = useState();
  const [totalStoreCharge, setTotalStoreCharge] = useState(0);
  const [totalServiceCharge, setTotalServiceCharge] = useState(0);
  const [dateEnd, setDateEnd] = useState();
  const [loading, setLoading] = useState(true);
  const [showSelect, setShowSelect] = useState(true);
  const [dateImp, setDateImp] = useState();
  const [HAWBs, setHAWBs] = useState([]);
  const { MAWB, HAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataLogin);
  const [type, setType] = useState("");
  const { t } = useTranslation("common");
  const history = useHistory();
  const getTitle = () => {
    if (billStatus === "E") return t("tabInfo.export");
    if (billStatus === "I" && billType === "H") return t("tabInfo.HAWB");
    if (billStatus === "I" && billType === "M") return t("tabInfo.import");
  };

  const columnsGroup = {
    export: [
      {
        className: "head-awb",
        title: t("tabInfo.org"),
        dataIndex: "ECA06_ORG",
        key: "ECA06_ORG",
      },
      {
        className: "head-awb",
        title: t("tabInfo.des"),
        dataIndex: "ECA07_DES",
        key: "ECA07_DES",
      },
      {
        className: "head-awb",
        title: t("tabInfo.pcs"),
        dataIndex: "ECA08_QTY_PCS",
        key: "ECA08_QTY_PCS",
      },
      {
        className: "head-awb",
        title: t("tabInfo.weight"),
        dataIndex: "ECA09_WGT_GRS",
        key: "ECA09_WGT_GRS",
      },
    ],
    importMAWB: [
      {
        className: "head-awb",
        title: t("tabInfo.pcs"),
        dataIndex: "B07_PCS",
        key: "B07_PCS",
      },
      {
        className: "head-awb",
        title: t("tabInfo.weight"),
        dataIndex: "B08_WEIGHT",
        key: "B08_WEIGHT",
      },
    ],
    importHAWB: [
      {
        className: "head-awb",
        title: "HAWB",
        dataIndex: "H25_HAWB_NO",
        key: "H25_HAWB_NO",
      },
      {
        className: "head-awb",
        title: t("tabInfo.pcs"),
        dataIndex: "H05_QTY_HAWB_PCS",
        key: "H05_QTY_HAWB_PCS",
      },
      {
        className: "head-awb",
        title: t("tabInfo.weight"),
        dataIndex: "H06_QTY_HAWB_WGT",
        key: "H06_QTY_HAWB_WGT",
      },
    ],
  };

  const [dataSourceTable, setDataSourceTable] = useState();
  const [titleTable, setTitleTable] = useState(
    `${t("tabInfo.mawbNo")}${MAWB.first}-${MAWB.last}`
  );
  const getDataExportGoods = async () => {
    // apis
    /// file lk_yc_exps
    try {
      /// GET GET_EXP_ACCEPTANCE
      /// GET GET_EXP_BOOKING_INF
      const bookingRes = await GET_EXP_BOOKING_INF(MAWB.first, MAWB.last);
      const acceptanceRes = await GET_EXP_ACCEPTANCE(MAWB.first, MAWB.last);
      if (
        acceptanceRes.status == 200 &&
        acceptanceRes.data != "" &&
        acceptanceRes.data.mCol.length
      ) {
        let data = [];
        acceptanceRes.data.mCol.map((dt) => {
          data.push({
            ECA06_ORG: dt.ECA06_ORG,
            ECA07_DES: dt.ECA07_DES,
            ECA08_QTY_PCS: dt.ECA08_QTY_PCS,
            ECA09_WGT_GRS: dt.ECA09_WGT_GRS,
          });
        });
        setStoreType(acceptanceRes.data.mCol[0].ECA17_COD_TYP_STG);
        setServiceType(acceptanceRes.data.mCol[0].ECA16_COD_SVR_TYP);
        setDataSourceTable(data);
      }
      if (
        bookingRes.status == 200 &&
        bookingRes.data != "" &&
        bookingRes.data.mCol.length
      ) {
        setFlightNo(
          `${
            bookingRes.data.mCol[0].EBK04_COD_FLT_CAR +
            bookingRes.data.mCol[0].EBK05_COD_FLT_NUM
          }`
        );
      }
      let startDate = moment(acceptanceRes.data.mCol[0].ECA18_ACCP_DATE).format(
        "LLL"
      );
      let endDate = moment(
        `${bookingRes.data.mCol[0].EBK15_DAT_STD} ${bookingRes.data.mCol[0].EBK16_TIM_STD}`
      ).format("LLL");
      setDateBegin(startDate);
      setDateEnd(endDate);
      /// GET GET_CHARGE_INF params: dateBegin from GET_EXP_ACCEPTANCE / dateEnd from GET_EXP_BOOKING_INF
      await GET_CHARGE_INF(startDate, endDate, token);

      /// GET CALC_CHARGE params: date from dateEnd  GET_EXP_BOOKING_INF
      const calcChargeRes = await CALC_CHARGE(
        MAWB.first,
        MAWB.last,
        "",
        "",
        endDate,
        sessionId,
        token
      );
      calcChargeRes.data === "OK" && setLoading(false);
      /// GET GET_CHARGE_INF_CS params awb_suffix unknown, HAWB_NO = ""
      /// GET GET_CHARGE_CODE_CS params: date from dateEnd  GET_EXP_BOOKING_INF
      const chargeInfoCsRes = await GET_CHARGE_INF_CS(
        MAWB.first,
        MAWB.last,
        "",
        "",
        "",
        sessionId,
        token
      );
      setTotalSunday(chargeInfoCsRes.data.Str_SUNDAY);
      setTotalHoliday(chargeInfoCsRes.data.Str_HOLIDAY);
      setTotalFreeDay(chargeInfoCsRes.data.Str_FREE_DAY);
      setTotalDayCharge(chargeInfoCsRes.data.Str_TOTAL_DAY_CHARGE);
      setMultiStore(chargeInfoCsRes.data.MULTI_STORAGE);

      const chargeCodeCsRes = await GET_CHARGE_CODE_CS(
        MAWB.first,
        MAWB.last,
        "",
        "",
        endDate,
        sessionId,
        token
      );
      /// GET GET_CHG_DTL_WEB (params from GET_CHARGE_CODE_CS) params chrg_code from  GET_CHARGE_CODE_CS
      /// call 2 lan (1 cho chi tiet luu kho, 1 cho chi tiet dich) phan biet = COD_CHG
      chargeCodeCsRes.data.mCol.map(async (res) => {
        if (res.COD_CHG.startsWith("E_ST")) {
          const chargeDetailRes = await GET_CHG_DTL_WEB(
            MAWB.first,
            MAWB.last,
            "",
            "",
            res.COD_CHG,
            "",
            sessionId,
            langId === "2" ? "V" : "E",
            token
          );

          const chargeDetail = chargeDetailRes.data.split("*-$-*");
          setDetailStoreCharge((storeCharge) =>
            storeCharge !== ""
              ? (storeCharge += ` <hr/> ${t("agentReport.chgCode")}: ${
                  res.COD_CHG
                } \n${chargeDetail[0]}`)
              : ` ${t("agentReport.chgCode")}: ${res.COD_CHG}\n${
                  chargeDetail[0]
                }`
          );
          setStoreVatRate(chargeDetail[1]);
          setTotalStoreCharge((totalStoreCharge) =>
            totalStoreCharge !== ""
              ? (totalStoreCharge =
                  Number(totalStoreCharge.toString().replace(/,/g, "")) +
                  Number(chargeDetail[3].replace(/,/g, "")))
              : chargeDetail[3].replace(/,/g, "")
          );
        }
        if (res.COD_CHG.slice(0, 4) === "E_SV") {
          const chargeDetailRes = await GET_CHG_DTL_WEB(
            MAWB.first,
            MAWB.last,
            "",
            "",
            res.COD_CHG,
            "",
            sessionId,
            langId === "2" ? "V" : "E",
            token
          );
          const chargeDetail = chargeDetailRes.data.split("*-$-*");
          setDetailServiceCharge(chargeDetail[0]);
          setServiceVatRate(chargeDetail[1]);
          setTotalServiceCharge(chargeDetail[3].replace(/,/g, ""));
        }
      });
    } catch (e) {
      if (e.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification["error"]({
          message: "Lỗi",
          description: e,
          style: { marginRight: "-50%" },
        });
    }
  };

  const getDateForSelect = async () => {
    if (billType === "M") {
      try {
        let params = {
          STR_CONNECT: "sss",
          AWB_PFX: MAWB.first,
          AWB_NO: MAWB.last,
          HAWB_NO: "*",
          FL_NO: "CX0767",
          FL_DATE: "3/31/2013",
        };
        let url = "api/GET_MAWB_INF";
        const billDetailRes = await GetBillDetail(url, params, token);
        if (billDetailRes.data.mCol.length === 0) {
          return;
        }
        if (
          billDetailRes.status == 200 &&
          billDetailRes.data != "" &&
          billDetailRes.data.mCol.length >= 1
        ) {
          const { B11_CARRIER_CODE, B12_FLIGHT_NUM, B06_FL_DATE } =
            billDetailRes.data.mCol[0];
          let data = [];
          billDetailRes.data.mCol.map((dt) => {
            data.push({ B07_PCS: dt.B07_PCS, B08_WEIGHT: dt.B08_WEIGHT });
          });
          setDataSourceTable(data);
          const impFlightInfoRes = await GET_IMP_FLIGHT_INF(
            B11_CARRIER_CODE,
            B12_FLIGHT_NUM,
            B06_FL_DATE,
            token
          );
          setImpATA(moment());
        }
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");
          history.push("/");
        } else
          notification["error"]({
            message: "Lỗi",
            description: "Mã vận đơn không hợp lệ.",
            style: { marginRight: "-50%" },
          });
      }
    }
    if (billType === "H") {
      try {
        let params = {
          STR_CONNECT: "sss",
          AWB_PFX: MAWB.first,
          AWB_NO: MAWB.last,
          HAWB_NO: "*",
          FL_NO: "CX0767",
          FL_DATE: "3/31/2013",
        };
        let url = "api/GET_MAWB_INF";
        const billDetailRes = await GetBillDetail(url, params, token);
        if (billDetailRes.data.mCol.length === 0) {
          return;
        }
        if (
          billDetailRes.status == 200 &&
          billDetailRes.data != "" &&
          billDetailRes.data.mCol.length >= 1
        ) {
          const { B11_CARRIER_CODE, B12_FLIGHT_NUM, B06_FL_DATE } =
            billDetailRes.data.mCol[0];
          await GET_IMP_FLIGHT_INF(
            B11_CARRIER_CODE,
            B12_FLIGHT_NUM,
            B06_FL_DATE,
            token
          );
          setImpATA(moment());
          const hawbDocInfoRes = await GET_HAWB_DOC_INF(
            MAWB.first,
            MAWB.last,
            "*",
            token
          );
          setHAWBs(hawbDocInfoRes.data.mCol);
          form.setFieldsValue({
            ...form,
            hawb: hawbDocInfoRes.data.mCol[0].H25_HAWB_NO,
          });
        }
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");
          history.push("/");
        } else
          notification["error"]({
            message: "Lỗi",
            description: error,
            style: { marginRight: "-50%" },
          });
      }
    }
  };
  const handleSelectForm = (values) => {
    dispatch(setHAWB(values.hawb));
    setDateImp(moment(values.date).format("LL HH:mm"));
    const getDataImportGoods = async () => {
      // apis
      ///file lk_yc_imp
      /// GET GET_MAWB_INF (co the dung lai tu TabInfo)
      /// GET GET_IMP_FLIGHT_INF params from GET_MAWB_INF
      /// GET GET_SHIPMENT_INF
      try {
        const shipmentInfoRes = await GET_SHIPMENT_INF(
          MAWB.first,
          MAWB.last,
          "",
          token
        );
        if (shipmentInfoRes.data.mCol.length == 1) {
          setFlightNo(
            `${
              shipmentInfoRes.data.mCol[0].S09_COD_SHP_CAR +
              shipmentInfoRes.data.mCol[0].S10_COD_SHP_FLT
            }`
          );
          shipmentInfoRes.data.mCol[0].S26_IS_EXP === "Y"
            ? setServiceType("Y")
            : setServiceType(shipmentInfoRes.data.mCol[0].S25_SERVICE_RQ);
          const _awbSuffix = shipmentInfoRes.data.mCol[0].S03_COD_AWB_SFX;
          const mawbLocationRes = await GET_MAWB_LOCATION(
            MAWB.first,
            MAWB.last,
            token
          );
          setMawbLocation(mawbLocationRes);
          /// GET CALC_CHARGE params: date from selectform
          const calcChargeRes = await CALC_CHARGE(
            MAWB.first,
            MAWB.last,
            _awbSuffix,
            "",
            moment(values.date).format("LL HH:mm"),
            sessionId,
            token
          );
          calcChargeRes.data === "OK" && setLoading(false);
          const chargeInfoCsRes = await GET_CHARGE_INF_CS(
            MAWB.first,
            MAWB.last,
            _awbSuffix,
            "",
            "",
            sessionId,
            token
          );
          setDateBegin(chargeInfoCsRes.data.Str_DAT_START);
          setDateEnd(chargeInfoCsRes.data.Str_DAT_END);
          setStoreType(chargeInfoCsRes.data.Str_STORAGE_TYPE);
          setTotalSunday(chargeInfoCsRes.data.Str_SUNDAY);
          setTotalHoliday(chargeInfoCsRes.data.Str_HOLIDAY);
          setTotalFreeDay(chargeInfoCsRes.data.Str_FREE_DAY);
          setTotalDayCharge(chargeInfoCsRes.data.Str_TOTAL_DAY_CHARGE);
          setMultiStore(chargeInfoCsRes.data.MULTI_STORAGE);

          const chargeCodeCsRes = await GET_CHARGE_CODE_CS(
            MAWB.first,
            MAWB.last,
            _awbSuffix,
            "",
            moment(values.date).format("LL HH:mm"),
            sessionId,
            token
          );
          chargeCodeCsRes.data.mCol.map(async (res) => {
            if (res.COD_CHG.slice(0, 4) === "I_ST") {
              const chargeDetailRes = await GET_CHG_DTL_WEB(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                "",
                res.COD_CHG,
                "",
                sessionId,
                langId === "2" ? "V" : "E",
                token
              );

              const chargeDetail = chargeDetailRes.data.split("*-$-*");
              setDetailStoreCharge((detailStoreCharge) =>
                detailStoreCharge !== ""
                  ? (detailStoreCharge += `<hr/> ${t("agentReport.chgCode")}: ${
                      res.COD_CHG
                    }\n${chargeDetail[0]}`)
                  : ` ${t("agentReport.chgCode")}: ${res.COD_CHG}\n${
                      chargeDetail[0]
                    }`
              );
              setStoreVatRate(chargeDetail[1]);
              setTotalStoreCharge((totalStoreCharge) =>
                totalStoreCharge
                  ? (totalStoreCharge =
                      Number.parseFloat(totalStoreCharge.replace(/,/g, "")) +
                      Number.parseFloat(chargeDetail[3].replace(/,/g, "")))
                  : chargeDetail[3].replace(/,/g, "")
              );
            }
            if (res.COD_CHG.slice(0, 4) === "I_SV") {
              const chargeDetailRes = await GET_CHG_DTL_WEB(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                "",
                res.COD_CHG,
                "",
                sessionId,
                langId === "2" ? "V" : "E",
                token
              );
              const chargeDetail = chargeDetailRes.data.split("*-$-*");
              setDetailServiceCharge(chargeDetail[0]);
              setServiceVatRate(chargeDetail[1]);
              setTotalServiceCharge(chargeDetail[3].replace(/,/g, ""));
            }
          });
          setShipments([]);
        }
        if (shipmentInfoRes.data.mCol.length > 1) {
          const shipmentsInfoArray = [...shipmentInfoRes.data.mCol].reverse();
          await Promise.all(
            shipmentsInfoArray.map(async (shipment) => {
              let shipmentData = {
                weight: shipment.S06_QTY_SHP_WGT,
                flightNo: `${
                  shipment.S09_COD_SHP_CAR + shipment.S10_COD_SHP_FLT
                }`,
                quantity: shipment.S05_QTY_SHP_PCS,
                reWeight: shipment.S18_SHP_RE_WGT,
              };
              shipment.S26_IS_EXP === "Y"
                ? (shipmentData = {
                    ...shipmentData,
                    serviceType: shipment.S26_IS_EXP,
                  })
                : (shipmentData = {
                    ...shipmentData,
                    serviceType: shipment.S25_SERVICE_RQ,
                  });

              const _awbSuffix = shipment.S03_COD_AWB_SFX;
              const mawbLocationRes = await GET_MAWB_LOCATION(
                MAWB.first,
                MAWB.last,
                token
              );
              setMawbLocation(mawbLocationRes);
              const _sessionId = cryptoRandomString({ length: 40 });
              /// GET CALC_CHARGE params: date from selectform
              const calcChargeRes = await CALC_CHARGE(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                "",
                moment(values.date).format("LL HH:mm"),
                _sessionId,
                token
              );
              calcChargeRes.data === "OK" && setLoading(false);

              const chargeInfoCsRes = await GET_CHARGE_INF_CS(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                "",
                "",
                _sessionId,
                token
              );
              shipmentData = {
                ...shipmentData,
                dateBegin: chargeInfoCsRes.data.Str_DAT_START,
                dateEnd: chargeInfoCsRes.data.Str_DAT_END,
                storeType: chargeInfoCsRes.data.Str_STORAGE_TYPE,
                totalSunday: chargeInfoCsRes.data.Str_SUNDAY,
                totalHoliday: chargeInfoCsRes.data.Str_HOLIDAY,
                totalFreeDay: chargeInfoCsRes.data.Str_FREE_DAY,
                totalDayCharge: chargeInfoCsRes.data.Str_TOTAL_DAY_CHARGE,
                multiStore: chargeInfoCsRes.data.MULTI_STORAGE,
              };
              const chargeCodeCsRes = await GET_CHARGE_CODE_CS(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                "",
                moment(values.date).format("LL HH:mm"),
                _sessionId,
                token
              );
              const promiseArr = [];
              chargeCodeCsRes.data.mCol.map((res, index) => {
                const getChargeDetail = async () => {
                  if (res.COD_CHG.startsWith("I_ST")) {
                    const chargeDetailRes = await GET_CHG_DTL_WEB(
                      MAWB.first,
                      MAWB.last,
                      _awbSuffix,
                      "",
                      res.COD_CHG,
                      "",
                      _sessionId,
                      langId === "2" ? "V" : "E",
                      token
                    );
                    const chargeDetail = chargeDetailRes.data.split("*-$-*");

                    shipmentData = {
                      ...shipmentData,
                      detailStoreCharge:
                        detailStoreCharge !== ""
                          ? (detailStoreCharge += `<hr/> ${t(
                              "agentReport.chgCode"
                            )}: ${res.COD_CHG}\n ${chargeDetail[0]}`)
                          : ` ${t("agentReport.chgCode")}: ${res.COD_CHG}\n ${
                              chargeDetail[0]
                            }`,
                      storeVatRate: chargeDetail[1],
                      totalStoreCharge:
                        totalStoreCharge !== ""
                          ? (totalStoreCharge =
                              Number.parseFloat(
                                totalStoreCharge.replace(/,/g, "")
                              ) +
                              Number.parseFloat(
                                chargeDetail[3].replace(/,/g, "")
                              ))
                          : chargeDetail[3].replace(/,/g, ""),
                    };
                  }
                  if (res.COD_CHG.slice(0, 4) === "I_SV") {
                    const chargeDetailRes = await GET_CHG_DTL_WEB(
                      MAWB.first,
                      MAWB.last,
                      _awbSuffix,
                      "",
                      res.COD_CHG,
                      "",
                      _sessionId,
                      langId === "2" ? "V" : "E",
                      token
                    );
                    const chargeDetail = chargeDetailRes.data.split("*-$-*");
                    shipmentData = {
                      ...shipmentData,
                      detailServiceCharge: chargeDetail[0],
                      serviceVatRate: chargeDetail[1],
                      totalServiceCharge: chargeDetail[3].replace(/,/g, ""),
                    };
                  }
                };
                promiseArr.push(getChargeDetail());
              });
              await Promise.all(promiseArr);
              setShipments((prevState) => [...prevState, shipmentData]);
              shipmentData.totalServiceCharge &&
                setTotalServiceCharge(
                  (prevState) =>
                    prevState + Number(shipmentData.totalServiceCharge)
                );
              shipmentData.totalStoreCharge &&
                setTotalStoreCharge(
                  (prevState) =>
                    prevState + Number(shipmentData.totalStoreCharge)
                );
            })
          );
        }
        /// GET GET_MAWB_LOCATION params HAWB_NO from GET_HAWB_SHIPMENT_INF, suffix from GET_HAWB_SHIPMENT_INF
        /// GET GET_CHARGE_INF_CS params HAWB_NO, date selectform
        /// GET GET_CHARGE_CODE_CS params HAWB_NO from GET_HAWB_SHIPMENT_INF , date unknown
        /// GET GET_CHG_DTL_WEB (params from GET_CHARGE_CODE_CS) params chrg_code from  GET_CHARGE_CODE_CS
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");
          history.push("/");
        } else
          notification["error"]({
            message: "Lỗi",
            description: "",
            style: { marginRight: "-50%" },
          });
      }
    };
    if (billType === "M" && billStatus === "I") {
      getDataImportGoods();
    }
    const getDataHawbGoods = async () => {
      // apis
      ///file lk_yc_exp
      /// GET GET_HAWB_DOC_INF
      /// GET GET_HAWB_SHIPMENT_INF hawbNo ,date from Select form
      try {
        const hawbDocInfoRes = await GET_HAWB_DOC_INF(
          MAWB.first,
          MAWB.last,
          values.hawb,
          token
        );
        let data = [];
        hawbDocInfoRes.data.mCol.map((dt) => {
          data.push({
            H25_HAWB_NO: dt.H25_HAWB_NO,
            H05_QTY_HAWB_PCS: dt.H05_QTY_HAWB_PCS,
            H06_QTY_HAWB_WGT: dt.H06_QTY_HAWB_WGT,
          });
        });
        setDataSourceTable(data);

        const hawbShipmentInfoRes = await GET_HAWB_SHIPMENT_INF(
          MAWB.first,
          MAWB.last,
          values.hawb,
          token
        );
        if (hawbShipmentInfoRes.data.mCol.length === 1) {
          const {
            H09_COD_HAWB_CAR,
            H10_COD_HAWB_FLT,
            H03_COD_AWB_SFX,
            H26_SERVICE_RQ,
            H25_HAWB_NO,
            H27_IS_EXP,
          } = hawbShipmentInfoRes.data.mCol[0];
          setFlightNo(`${H09_COD_HAWB_CAR + H10_COD_HAWB_FLT}`);
          const _awbSuffix = H03_COD_AWB_SFX;
          H27_IS_EXP === "Y"
            ? setServiceType(H27_IS_EXP)
            : setServiceType(H26_SERVICE_RQ);
          /// GET CALC_CHARGE params: date from selectform
          const calcChargeRes = await CALC_CHARGE(
            MAWB.first,
            MAWB.last,
            _awbSuffix,
            H25_HAWB_NO,
            moment(values.date).format("LL HH:mm"),
            sessionId,
            token
          );
          calcChargeRes.data === "OK" && setLoading(false);

          /// GET GET_CHARGE_INF_CS params HAWB_NO, date selectform
          const chargeInfoCsRes = await GET_CHARGE_INF_CS(
            MAWB.first,
            MAWB.last,
            _awbSuffix,
            H25_HAWB_NO,
            "",
            sessionId,
            token
          );
          setDateBegin(chargeInfoCsRes.data.Str_DAT_START);
          setDateEnd(chargeInfoCsRes.data.Str_DAT_END);
          setStoreType(chargeInfoCsRes.data.Str_STORAGE_TYPE);
          setTotalSunday(chargeInfoCsRes.data.Str_SUNDAY);
          setTotalHoliday(chargeInfoCsRes.data.Str_HOLIDAY);
          setTotalFreeDay(chargeInfoCsRes.data.Str_FREE_DAY);
          setTotalDayCharge(chargeInfoCsRes.data.Str_TOTAL_DAY_CHARGE);
          setMultiStore(chargeInfoCsRes.data.MULTI_STORAGE);
          /// GET GET_HAWB_LOCATION params HAWB_NO from GET_HAWB_SHIPMENT_INF, suffix from GET_HAWB_SHIPMENT_INF
          const hawbLocationRes = await GET_HAWB_LOCATION(
            MAWB.first,
            MAWB.last,
            H25_HAWB_NO,
            token
          );
          setHawbLocation(hawbLocationRes.data.mCol);
          /// GET GET_CHARGE_CODE_CS params HAWB_NO from GET_HAWB_SHIPMENT_INF , date unknown
          const chargeCodeCsRes = await GET_CHARGE_CODE_CS(
            MAWB.first,
            MAWB.last,
            _awbSuffix,
            H25_HAWB_NO,
            moment(values.date).format("LL HH:mm"),
            sessionId,
            token
          );

          /// GET GET_CHG_DTL_WEB (params from GET_CHARGE_CODE_CS) params chrg_code from  GET_CHARGE_CODE_CS
          chargeCodeCsRes.data.mCol.map(async (res) => {
            if (res.COD_CHG.startsWith("I_ST")) {
              const chargeDetailRes = await GET_CHG_DTL_WEB(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                H25_HAWB_NO,
                res.COD_CHG,
                "",
                sessionId,
                langId === "2" ? "V" : "E",
                token
              );

              const chargeDetail = chargeDetailRes.data.split("*-$-*");
              setDetailStoreCharge((detailStoreCharge) =>
                detailStoreCharge
                  ? (detailStoreCharge += `<hr/> ${t("agentReport.chgCode")}: ${
                      res.COD_CHG
                    }\n${chargeDetail[0]}`)
                  : `${t("agentReport.chgCode")}: ${res.COD_CHG}\n ${
                      chargeDetail[0]
                    }`
              );
              setStoreVatRate(chargeDetail[1]);

              setTotalStoreCharge((totalStoreCharge) =>
                totalStoreCharge
                  ? (totalStoreCharge =
                      Number.parseFloat(totalStoreCharge.replace(/,/g, "")) +
                      Number.parseFloat(chargeDetail[3].replace(/,/g, "")))
                  : chargeDetail[3].replace(/,/g, "")
              );
            }
            if (res.COD_CHG.slice(0, 4) === "I_SV") {
              const chargeDetailRes = await GET_CHG_DTL_WEB(
                MAWB.first,
                MAWB.last,
                _awbSuffix,
                H25_HAWB_NO,
                res.COD_CHG,
                "",
                sessionId,
                langId === "2" ? "V" : "E",
                token
              );
              const chargeDetail = chargeDetailRes.data.split("*-$-*");
              setDetailServiceCharge(chargeDetail[0]);
              setServiceVatRate(chargeDetail[1]);
              setTotalServiceCharge(chargeDetail[3].replace(/,/g, ""));
            }
          });
          setShipments([]);
        }

        if (hawbShipmentInfoRes.data.mCol.length > 1) {
          const hawbShipmentInfoArray = [...hawbShipmentInfoRes.data.mCol];
          hawbShipmentInfoArray.map(async (shipment) => {
            let shipmentData = {
              weight: shipment.H06_QTY_HAWB_WGT,
              flightNo: `${
                shipment.H09_COD_HAWB_CAR + shipment.H10_COD_HAWB_FLT
              }`,
              quantity: shipment.H05_QTY_HAWB_PCS,
              reWeight: shipment.H18_HAWB_RE_WGT,
            };
            shipment.H27_IS_EXP === "Y"
              ? (shipmentData = {
                  ...shipmentData,
                  serviceType: shipment.H27_IS_EXP,
                })
              : (shipmentData = {
                  ...shipmentData,
                  serviceType: shipment.H26_SERVICE_RQ,
                });

            const _awbSuffix = shipment.H03_COD_AWB_SFX;
            await GET_HAWB_LOCATION(
              MAWB.first,
              MAWB.last,
              shipment.H25_HAWB_NO,
              token
            );
            const _sessionId = cryptoRandomString({ length: 40 });
            /// GET CALC_CHARGE params: date from selectform
            const calcChargeRes = await CALC_CHARGE(
              MAWB.first,
              MAWB.last,
              _awbSuffix,
              shipment.H25_HAWB_NO,
              moment(values.date).format("LL HH:mm"),
              _sessionId,
              token
            );
            calcChargeRes.data === "OK" && setLoading(false);

            const chargeInfoCsRes = await GET_CHARGE_INF_CS(
              MAWB.first,
              MAWB.last,
              _awbSuffix,
              shipment.H25_HAWB_NO,
              moment(values.date).format("LL HH:mm"),
              _sessionId,
              token
            );

            shipmentData = {
              ...shipmentData,
              dateBegin: chargeInfoCsRes.data.Str_DAT_START,
              dateEnd: chargeInfoCsRes.data.Str_DAT_END,
              storeType: chargeInfoCsRes.data.Str_STORAGE_TYPE,
              totalSunday: chargeInfoCsRes.data.Str_SUNDAY,
              totalHoliday: chargeInfoCsRes.data.Str_HOLIDAY,
              totalFreeDay: chargeInfoCsRes.data.Str_FREE_DAY,
              totalDayCharge: chargeInfoCsRes.data.Str_TOTAL_DAY_CHARGE,
              multiStore: chargeInfoCsRes.data.MULTI_STORAGE,
            };

            const chargeCodeCsRes = await GET_CHARGE_CODE_CS(
              MAWB.first,
              MAWB.last,
              _awbSuffix,
              shipment.H25_HAWB_NO,
              moment(values.date).format("LL HH:mm"),
              _sessionId,
              token
            );
            const promiseArr = [];
            chargeCodeCsRes.data.mCol.map((res, i) => {
              const getChargeDetail = async () => {
                if (res.COD_CHG.startsWith("I_ST")) {
                  const chargeDetailRes = await GET_CHG_DTL_WEB(
                    MAWB.first,
                    MAWB.last,
                    _awbSuffix,
                    shipment.H25_HAWB_NO,
                    res.COD_CHG,
                    "",
                    _sessionId,
                    langId === "2" ? "V" : "E",
                    token
                  );
                  const chargeDetail = chargeDetailRes.data.split("*-$-*");
                  shipmentData = {
                    ...shipmentData,
                    detailStoreCharge:
                      detailStoreCharge !== ""
                        ? (detailStoreCharge += `<hr/> ${t(
                            "agentReport.chgCode"
                          )}: ${res.COD_CHG}\n ${chargeDetail[0]}`)
                        : `${t("agentReport.chgCode")}: ${res.COD_CHG}\n ${
                            chargeDetail[0]
                          }`,
                    storeVatRate: chargeDetail[1],
                    totalStoreCharge:
                      totalStoreCharge !== ""
                        ? (totalStoreCharge =
                            Number.parseFloat(
                              totalStoreCharge.replace(/,/g, "")
                            ) +
                            Number.parseFloat(
                              chargeDetail[3].replace(/,/g, "")
                            ))
                        : chargeDetail[3].replace(/,/g, ""),
                  };
                }
                if (res.COD_CHG.slice(0, 4) === "I_SV") {
                  const chargeDetailRes = await GET_CHG_DTL_WEB(
                    MAWB.first,
                    MAWB.last,
                    _awbSuffix,
                    shipment.H25_HAWB_NO,
                    res.COD_CHG,
                    "",
                    _sessionId,
                    langId === "2" ? "V" : "E",
                    token
                  );
                  const chargeDetail = chargeDetailRes.data.split("*-$-*");
                  shipmentData = {
                    ...shipmentData,
                    detailServiceCharge: chargeDetail[0],
                    serviceVatRate: chargeDetail[1],
                    totalServiceCharge: chargeDetail[3].replace(/,/g, ""),
                  };
                }
              };
              promiseArr.push(getChargeDetail());
            });
            await Promise.all(promiseArr);
            setShipments((prevState) => [...prevState, shipmentData]);
            shipmentData.totalServiceCharge &&
              setTotalServiceCharge(
                (prevState) =>
                  prevState + Number(shipmentData.totalServiceCharge)
              );
            shipmentData.totalStoreCharge &&
              setTotalStoreCharge(
                (prevState) => prevState + Number(shipmentData.totalStoreCharge)
              );
          });
        }
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");
          history.push("/");
        } else
          notification["error"]({
            message: "Lỗi",
            description: error,
            style: { marginRight: "-50%" },
          });
      }

      /// GET GET_CHARGE_INF params startdate from GET_HAWB_SHIPMENT_INF, enddate = today
    };
    if (billType === "H" && billStatus === "I") {
      getDataHawbGoods();
    }
    setShowSelect(false);
  };
  useEffect(() => {
    setType(getTitle());
    setTotalSunday(0);
    setTotalHoliday(0);

    billStatus === "E" && getDataExportGoods();
    billStatus === "I" && getDateForSelect();
  }, [billStatus, billType, dateImp, HAWB]);

  return (
    <>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>
            {t("tabInfo.chargeTitle")} {`- ${type}`}
          </h5>
        </Col>
      </Row>
      {billStatus === "I" && impATA !== undefined && showSelect && (
        <>
          <Row className="select-header-wrapper">
            <Col
              style={{ fontWeight: "bold" }}
              className="select-header"
              span={24}
            >
              {t("tabInfo.mawbNo")}
              {MAWB.first}-{MAWB.last}
            </Col>
          </Row>
          <Row className="awb-tab-content">
            <Row gutter={[0, 16]} className="control-wrapper">
              <Col
                offset={HAWBs.length > 1 ? 7 : 9}
                span={15}
                className="control"
              >
                <Form
                  initialValues={{
                    date: impATA,
                  }}
                  className="form-hawb"
                  onFinish={handleSelectForm}
                  layout={"inline"}
                  form={form}
                  autoComplete="off"
                >
                  {HAWBs.length > 0 && (
                    <Form.Item name="hawb" label="Số HAWB:	">
                      <Select>
                        {HAWBs.map((HAWB, index) => {
                          return (
                            <Option key={index} value={HAWB.H25_HAWB_NO}>
                              {HAWB.H25_HAWB_NO}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    name="date"
                    label={t("tabInfo.formSelectDate.choose")}
                  >
                    <DatePicker format="DD/MM/YYYY HH:mm" showTime />
                  </Form.Item>
                  <Col span={24} className="control">
                    <Row className="control-button-group-wrapper">
                      <Col offset={5}>
                        <Button
                          style={{ width: "fit-content" }}
                          htmlType={"submit"}
                        >
                          {langId === "2" ? "Chọn" : "Next"}
                        </Button>
                      </Col>
                      <Col>
                        <Button style={{ width: "fit-content" }}>
                          {langId === "2" ? "Bỏ qua" : "Skip"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Row>
        </>
      )}
      {billStatus === "I" && billType === "H" && !showSelect && (
        <>
          <Row className="result-content-wrapper">
            <Col className="awb-result-content" span={24}>
              <Skeleton loading={loading}>
                <Table
                  columns={columnsGroup.importHAWB}
                  dataSource={dataSourceTable}
                  title={() => titleTable}
                  bordered
                  // scroll={{ x: 1300 }}
                  pagination={false}
                />
              </Skeleton>
            </Col>
          </Row>
          <Row className="result-title-wrapper">
            <Col span={24} className="awb-result-title">
              <p>{t("tabInfo.chargeInfo")}</p>
            </Col>
          </Row>
          {shipments.length == 0 && (
            <>
              <Row className="result-content-wrapper">
                <Col className="awb-result-content" span={24}>
                  <Skeleton loading={loading}>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.flightNo")}>
                        {flightNo || ""}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.storeType")}>
                        {storeType === "AVI"
                          ? t("tabInfo.goods.livestock")
                          : storeType === "COL"
                          ? t("tabInfo.goods.coolRoom")
                          : storeType === "DGR"
                          ? t("tabInfo.goods.dangerous")
                          : storeType === "RXB"
                          ? t("tabInfo.goods.explosives")
                          : storeType === "VAL"
                          ? t("tabInfo.goods.valuable")
                          : storeType === "GEN"
                          ? t("tabInfo.goods.general")
                          : storeType === "PER"
                          ? t("tabInfo.goods.perishable")
                          : storeType === "HUM"
                          ? t("tabInfo.goods.hum")
                          : storeType === "VUN"
                          ? t("tabInfo.goods.vulnerable")
                          : ""}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.serviceType")}>
                        {serviceType === "AVI"
                          ? t("tabInfo.goods.livestock")
                          : serviceType === "COL"
                          ? t("tabInfo.goods.coolRoom")
                          : serviceType === "DGR"
                          ? t("tabInfo.goods.dangerous")
                          : serviceType === "RXB"
                          ? t("tabInfo.goods.explosives")
                          : serviceType === "VAL"
                          ? t("tabInfo.goods.valuable")
                          : serviceType === "GEN"
                          ? t("tabInfo.goods.general")
                          : serviceType === "PER"
                          ? t("tabInfo.goods.perishable")
                          : serviceType === "Y"
                          ? t("tabInfo.goods.express")
                          : t("tabInfo.goods.vulnerable")}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.recDate")}>
                        {dateBegin}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.issueDate")}>
                        {dateEnd}
                      </Descriptions.Item>
                    </Descriptions>
                    {storeType === "GEN" ? (
                      <>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.sunday")}>
                            {parseInt(totalSunday) + parseInt(totalHoliday)}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.free")}>
                            {totalFreeDay}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.chargeDays")}>
                            {totalDayCharge} {t("tabInfo.chargeDaysNote1")}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label="">
                            {parseInt(totalDayCharge) + parseInt(totalFreeDay)}{" "}
                            {t("tabInfo.chargeDaysNote2")}
                          </Descriptions.Item>
                        </Descriptions>
                      </>
                    ) : (
                      <>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.chargeDays")}>
                            {totalDayCharge}
                          </Descriptions.Item>
                        </Descriptions>
                      </>
                    )}
                  </Skeleton>
                </Col>
              </Row>

              <Row className="result-content-wrapper">
                <Col className="awb-result-content" span={24}>
                  {detailServiceCharge && (
                    <Skeleton loading={loading}>
                      <Descriptions
                        column={4}
                        layout={"vertical"}
                        className="charges-detail-result"
                        bordered
                        size={"small"}
                      >
                        <Descriptions.Item
                          label={t("tabInfo.contentTitle")}
                          className="detail-label"
                        >
                          {t("tabInfo.serviceCharge")}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-content"
                          label={t("tabInfo.descripe")}
                        >
                          {Parser(detailServiceCharge) || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-vat text-center"
                          label={t("tabInfo.tax")}
                        >
                          {`${serviceVatRate}%` || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="text-right"
                          label={t("tabInfo.total")}
                        >
                          {totalServiceCharge &&
                            totalServiceCharge.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                        </Descriptions.Item>
                      </Descriptions>
                    </Skeleton>
                  )}
                  {detailStoreCharge && (
                    <Skeleton loading={loading}>
                      <Descriptions
                        column={4}
                        layout={"vertical"}
                        className="charges-detail-result"
                        bordered
                        size={"small"}
                      >
                        <Descriptions.Item
                          className="detail-label"
                          label={
                            !detailServiceCharge
                              ? t("tabInfo.contentTitle")
                              : ""
                          }
                        >
                          {t("tabInfo.storageCharge")}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-content"
                          label={
                            !detailServiceCharge ? t("tabInfo.descripe") : ""
                          }
                        >
                          {Parser(detailStoreCharge) || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-vat text-center"
                          label={!detailServiceCharge ? t("tabInfo.tax") : ""}
                        >
                          {`${storeVatRate}%` || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="text-right"
                          label={!detailServiceCharge ? t("tabInfo.total") : ""}
                        >
                          {totalStoreCharge &&
                            totalStoreCharge
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Descriptions.Item>
                      </Descriptions>
                    </Skeleton>
                  )}
                </Col>
              </Row>
            </>
          )}
          {shipments.length > 1 &&
            shipments.map((shipment, index) => {
              return (
                <React.Fragment key={index}>
                  <Row className="result-content-wrapper awb-result-wrapper">
                    <Col className="awb-result-content-index" span={4}>
                      {langId === "2" ? "Lô hàng thứ" : "No"} {index + 1} (
                      {shipment.weight}kg)
                    </Col>
                    <Col className="awb-result-content" span={20}>
                      <Skeleton loading={loading}>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.flightNo")}>
                            {shipment.flightNo || ""}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.storeType")}>
                            {shipment.storeType === "AVI"
                              ? t("tabInfo.goods.livestock")
                              : shipment.storeType === "COL"
                              ? t("tabInfo.goods.coolRoom")
                              : shipment.storeType === "DGR"
                              ? t("tabInfo.goods.dangerous")
                              : shipment.storeType === "RXB"
                              ? t("tabInfo.goods.explosives")
                              : shipment.storeType === "VAL"
                              ? t("tabInfo.goods.valuable")
                              : shipment.storeType === "GEN"
                              ? t("tabInfo.goods.general")
                              : shipment.storeType === "PER"
                              ? t("tabInfo.goods.perishable")
                              : shipment.storeType === "HUM"
                              ? t("tabInfo.goods.hum")
                              : t("tabInfo.goods.vulnerable")}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.serviceType")}>
                            {shipment.serviceType === "AVI"
                              ? t("tabInfo.goods.livestock")
                              : shipment.serviceType === "COL"
                              ? t("tabInfo.goods.coolRoom")
                              : shipment.serviceType === "DGR"
                              ? t("tabInfo.goods.dangerous")
                              : shipment.serviceType === "RXB"
                              ? t("tabInfo.goods.explosives")
                              : shipment.serviceType === "VAL"
                              ? t("tabInfo.goods.valuable")
                              : shipment.serviceType === "GEN"
                              ? t("tabInfo.goods.general")
                              : shipment.serviceType === "PER"
                              ? t("tabInfo.goods.perishable")
                              : shipment.serviceType === "Y"
                              ? t("tabInfo.goods.express")
                              : t("tabInfo.goods.vulnerable")}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.pcs")}>
                            {shipment.quantity}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.weight")}>
                            {shipment.weight}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.reWeight")}>
                            {shipment.reWeight}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.recDate")}>
                            {shipment.dateBegin}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.issueDate")}>
                            {shipment.dateEnd}
                          </Descriptions.Item>
                        </Descriptions>
                        {shipment.storeType === "GEN" ? (
                          <>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label={t("tabInfo.sunday")}>
                                {parseInt(shipment.totalSunday) +
                                  parseInt(shipment.totalHoliday)}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label={t("tabInfo.free")}>
                                {shipment.totalFreeDay}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item
                                label={t("tabInfo.chargeDays")}
                              >
                                {shipment.totalDayCharge}
                                {t("tabInfo.chargeDaysNote1")}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label="">
                                {parseInt(shipment.totalDayCharge) +
                                  parseInt(shipment.totalFreeDay)}
                                {t("tabInfo.chargeDaysNote2")}
                              </Descriptions.Item>
                            </Descriptions>
                          </>
                        ) : (
                          <>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item
                                label={t("tabInfo.chargeDays")}
                              >
                                {shipment.totalDayCharge}
                              </Descriptions.Item>
                            </Descriptions>
                          </>
                        )}
                      </Skeleton>
                      <Row className="result-content-wrapper">
                        <Col className="awb-result-content" span={24}>
                          {shipment.detailServiceCharge && (
                            <Skeleton loading={loading}>
                              <Descriptions
                                column={4}
                                layout={"vertical"}
                                className="charges-detail-result"
                                bordered
                                size={"small"}
                              >
                                <Descriptions.Item
                                  label={t("tabInfo.contentTitle")}
                                  className="detail-label"
                                >
                                  {t("tabInfo.serviceCharge")}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-content"
                                  label={t("tabInfo.descripe")}
                                >
                                  {Parser(shipment.detailServiceCharge) || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-vat text-center"
                                  label={t("tabInfo.tax")}
                                >
                                  {`${shipment.serviceVatRate}%` || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="text-right"
                                  label={t("tabInfo.total")}
                                >
                                  {shipment.totalServiceCharge !== undefined &&
                                    shipment.totalServiceCharge.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Descriptions.Item>
                              </Descriptions>
                            </Skeleton>
                          )}
                          {shipment.detailStoreCharge && (
                            <Skeleton loading={loading}>
                              <Descriptions
                                column={4}
                                layout={"vertical"}
                                className="charges-detail-result"
                                bordered
                                size={"small"}
                              >
                                <Descriptions.Item
                                  className="detail-label"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.contentTitle")
                                      : ""
                                  }
                                >
                                  {t("tabInfo.storageCharge")}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-content"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.descripe")
                                      : ""
                                  }
                                >
                                  {Parser(shipment.detailStoreCharge) || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-vat text-center"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.tax")
                                      : ""
                                  }
                                >
                                  {`${shipment.storeVatRate}%` || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="text-right"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.total")
                                      : ""
                                  }
                                >
                                  {shipment.totalStoreCharge !== undefined &&
                                    shipment.totalStoreCharge
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Descriptions.Item>
                              </Descriptions>
                            </Skeleton>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            })}
          <Row className="result-content-wrapper  total-row">
            <Col className="awb-result-content" offset={21} span={"auto"}>
              {t("tabInfo.total")}:&nbsp;
              {totalStoreCharge !== undefined &&
              totalServiceCharge !== undefined
                ? (Number(totalStoreCharge) + Number(totalServiceCharge))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalStoreCharge !== undefined
                ? totalStoreCharge
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalServiceCharge !== undefined
                ? totalServiceCharge.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : ""}
            </Col>
          </Row>
          <Row className="result-content-wrapper  total-row">
            <Col className="awb-result-content" span={"auto"}>
              {t("tabInfo.note")}
            </Col>
          </Row>
          <ChargeInfoControls setIsShowCharges={props.setIsShowCharges} />
        </>
      )}
      {billStatus === "I" && billType === "M" && !showSelect && (
        <>
          <Row className="result-content-wrapper">
            <Col className="awb-result-content" span={24}>
              <Skeleton loading={loading}>
                <Table
                  columns={columnsGroup.importMAWB}
                  dataSource={dataSourceTable}
                  title={() => titleTable}
                  bordered
                  // scroll={{ x: 1300 }}
                  pagination={false}
                />
              </Skeleton>
            </Col>
          </Row>
          <Row className="result-title-wrapper">
            <Col span={24} className="awb-result-title">
              <p>{t("tabInfo.chargeInfo")}</p>
            </Col>
          </Row>
          {shipments.length == 0 && (
            <>
              <Row className="result-content-wrapper">
                <Col className="awb-result-content" span={24}>
                  <Skeleton loading={loading}>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.flightNo")}>
                        {flightNo || ""}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.storeType")}>
                        {storeType === "AVI"
                          ? t("tabInfo.goods.livestock")
                          : storeType === "COL"
                          ? t("tabInfo.goods.coolRoom")
                          : storeType === "DGR"
                          ? t("tabInfo.goods.dangerous")
                          : storeType === "RXB"
                          ? t("tabInfo.goods.explosives")
                          : storeType === "VAL"
                          ? t("tabInfo.goods.valuable")
                          : storeType === "GEN"
                          ? t("tabInfo.goods.general")
                          : storeType === "PER"
                          ? t("tabInfo.goods.perishable")
                          : storeType === "HUM"
                          ? t("tabInfo.goods.hum")
                          : t("tabInfo.goods.vulnerable")}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.serviceType")}>
                        {serviceType === "AVI"
                          ? t("tabInfo.goods.livestock")
                          : serviceType === "COL"
                          ? t("tabInfo.goods.coolRoom")
                          : serviceType === "DGR"
                          ? t("tabInfo.goods.dangerous")
                          : serviceType === "RXB"
                          ? t("tabInfo.goods.explosives")
                          : serviceType === "VAL"
                          ? t("tabInfo.goods.valuable")
                          : serviceType === "GEN"
                          ? t("tabInfo.goods.general")
                          : serviceType === "PER"
                          ? t("tabInfo.goods.perishable")
                          : serviceType === "Y"
                          ? t("tabInfo.goods.express")
                          : t("tabInfo.goods.vulnerable")}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.recDate")}>
                        {dateBegin}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.issueDate")}>
                        {dateEnd}
                      </Descriptions.Item>
                    </Descriptions>
                    {storeType === "GEN" ? (
                      <>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.sunday")}>
                            {parseInt(totalSunday) + parseInt(totalHoliday)}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.free")}>
                            {totalFreeDay}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.chargeDays")}>
                            {totalDayCharge}
                            {t("tabInfo.chargeDaysNote1")}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label="">
                            {parseInt(totalDayCharge) + parseInt(totalFreeDay)}
                            {t("tabInfo.chargeDaysNote2")}
                          </Descriptions.Item>
                        </Descriptions>
                      </>
                    ) : (
                      <>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.chargeDays")}>
                            {totalDayCharge}
                          </Descriptions.Item>
                        </Descriptions>
                      </>
                    )}
                  </Skeleton>
                </Col>
              </Row>
              <Row className="result-content-wrapper">
                <Col className="awb-result-content" span={24}>
                  {detailServiceCharge && (
                    <Skeleton loading={loading}>
                      <Descriptions
                        column={4}
                        layout={"vertical"}
                        className="charges-detail-result"
                        bordered
                        size={"small"}
                      >
                        <Descriptions.Item
                          label={t("tabInfo.contentTitle")}
                          className="detail-label"
                        >
                          {t("tabInfo.serviceCharge")}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-content"
                          label={t("tabInfo.descripe")}
                        >
                          {Parser(detailServiceCharge) || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-vat text-center"
                          label={t("tabInfo.tax")}
                        >
                          {`${serviceVatRate}%` || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="text-right"
                          label={t("tabInfo.total")}
                        >
                          {totalServiceCharge &&
                            totalServiceCharge.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                        </Descriptions.Item>
                      </Descriptions>
                    </Skeleton>
                  )}
                  {detailStoreCharge && (
                    <Skeleton loading={loading}>
                      <Descriptions
                        column={4}
                        layout={"vertical"}
                        className="charges-detail-result"
                        bordered
                        size={"small"}
                      >
                        <Descriptions.Item
                          className="detail-label"
                          label={
                            !detailServiceCharge
                              ? t("tabInfo.contentTitle")
                              : ""
                          }
                        >
                          {t("tabInfo.storageCharge")}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-content"
                          label={
                            !detailServiceCharge ? t("tabInfo.descripe") : ""
                          }
                        >
                          {Parser(detailStoreCharge) || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="detail-vat text-center"
                          label={!detailServiceCharge ? t("tabInfo.tax") : ""}
                        >
                          {`${storeVatRate}%` || ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                          className="text-right"
                          label={!detailServiceCharge ? t("tabInfo.total") : ""}
                        >
                          {totalStoreCharge &&
                            totalStoreCharge
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Descriptions.Item>
                      </Descriptions>
                    </Skeleton>
                  )}
                </Col>
              </Row>
            </>
          )}
          {shipments.length > 1 &&
            shipments.map((shipment, index) => {
              return (
                <React.Fragment key={index}>
                  <Row className="result-content-wrapper awb-result-wrapper">
                    <Col className="awb-result-content-index" span={4}>
                      {langId === "2" ? "Lô hàng thứ" : "No"} {index + 1} (
                      {shipment.weight}kg)
                    </Col>
                    <Col className="awb-result-content" span={20}>
                      <Skeleton loading={loading}>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.flightNo")}>
                            {shipment.flightNo || ""}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.storeType")}>
                            {shipment.storeType === "AVI"
                              ? t("tabInfo.goods.livestock")
                              : shipment.storeType === "COL"
                              ? t("tabInfo.goods.coolRoom")
                              : shipment.storeType === "DGR"
                              ? t("tabInfo.goods.dangerous")
                              : shipment.storeType === "RXB"
                              ? t("tabInfo.goods.explosives")
                              : shipment.storeType === "VAL"
                              ? t("tabInfo.goods.valuable")
                              : shipment.storeType === "GEN"
                              ? t("tabInfo.goods.general")
                              : shipment.storeType === "PER"
                              ? t("tabInfo.goods.perishable")
                              : shipment.storeType === "HUM"
                              ? t("tabInfo.goods.hum")
                              : t("tabInfo.goods.vulnerable")}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.serviceType")}>
                            {shipment.serviceType === "AVI"
                              ? t("tabInfo.goods.livestock")
                              : shipment.serviceType === "COL"
                              ? t("tabInfo.goods.coolRoom")
                              : shipment.serviceType === "DGR"
                              ? t("tabInfo.goods.dangerous")
                              : shipment.serviceType === "RXB"
                              ? t("tabInfo.goods.explosives")
                              : shipment.serviceType === "VAL"
                              ? t("tabInfo.goods.valuable")
                              : shipment.serviceType === "GEN"
                              ? t("tabInfo.goods.general")
                              : shipment.serviceType === "PER"
                              ? t("tabInfo.goods.perishable")
                              : shipment.serviceType === "Y"
                              ? t("tabInfo.goods.express")
                              : t("tabInfo.goods.vulnerable")}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.pcs")}>
                            {shipment.quantity}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.weight")}>
                            {shipment.weight}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.reWeight")}>
                            {shipment.reWeight}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.recDate")}>
                            {shipment.dateBegin}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                          className="charges-result"
                          bordered
                          size={"small"}
                        >
                          <Descriptions.Item label={t("tabInfo.issueDate")}>
                            {shipment.dateEnd}
                          </Descriptions.Item>
                        </Descriptions>

                        {shipment.storeType === "GEN" ? (
                          <>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label={t("tabInfo.sunday")}>
                                {parseInt(shipment.totalSunday) +
                                  parseInt(shipment.totalHoliday)}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label={t("tabInfo.free")}>
                                {shipment.totalFreeDay}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item
                                label={t("tabInfo.chargeDays")}
                              >
                                {totalDayCharge}
                                {t("tabInfo.chargeDaysNote1")}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label="">
                                {parseInt(totalDayCharge) +
                                  parseInt(totalFreeDay)}
                                {t("tabInfo.chargeDaysNote2")}
                              </Descriptions.Item>
                            </Descriptions>{" "}
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item
                                label={t("tabInfo.chargeDays")}
                              >
                                {shipment.totalDayCharge}
                                {t("tabInfo.chargeDaysNote1")}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label="">
                                {parseInt(shipment.totalDayCharge) +
                                  parseInt(shipment.totalFreeDay)}
                                {t("tabInfo.chargeDaysNote2")}
                              </Descriptions.Item>
                            </Descriptions>
                          </>
                        ) : (
                          <>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item
                                label={t("tabInfo.chargeDays")}
                              >
                                {totalDayCharge}
                                {t("tabInfo.chargeDaysNote1")}
                              </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                              className="charges-result"
                              bordered
                              size={"small"}
                            >
                              <Descriptions.Item label="">
                                {parseInt(totalDayCharge) +
                                  parseInt(totalFreeDay)}
                                {t("tabInfo.chargeDaysNote2")}
                              </Descriptions.Item>
                            </Descriptions>
                          </>
                        )}
                      </Skeleton>
                      <Row className="result-content-wrapper">
                        <Col className="awb-result-content" span={24}>
                          {shipment.detailServiceCharge && (
                            <Skeleton loading={loading}>
                              <Descriptions
                                column={4}
                                layout={"vertical"}
                                className="charges-detail-result"
                                bordered
                                size={"small"}
                              >
                                <Descriptions.Item
                                  label={t("tabInfo.contentTitle")}
                                  className="detail-label"
                                >
                                  {t("tabInfo.serviceCharge")}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-content"
                                  label={t("tabInfo.descripe")}
                                >
                                  {Parser(shipment.detailServiceCharge) || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-vat text-center"
                                  label={t("tabInfo.tax")}
                                >
                                  {`${shipment.serviceVatRate}%` || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="text-right"
                                  label={t("tabInfo.total")}
                                >
                                  {shipment.totalServiceCharge !== undefined &&
                                    shipment.totalServiceCharge.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Descriptions.Item>
                              </Descriptions>
                            </Skeleton>
                          )}
                          {shipment.detailStoreCharge && (
                            <Skeleton loading={loading}>
                              <Descriptions
                                column={4}
                                layout={"vertical"}
                                className="charges-detail-result"
                                bordered
                                size={"small"}
                              >
                                <Descriptions.Item
                                  className="detail-label"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.contentTitle")
                                      : ""
                                  }
                                >
                                  {t("tabInfo.storageCharge")}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-content"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.descripe")
                                      : ""
                                  }
                                >
                                  {Parser(shipment.detailStoreCharge) || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="detail-vat text-center"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.tax")
                                      : ""
                                  }
                                >
                                  {`${shipment.storeVatRate}%` || ""}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  className="text-right"
                                  label={
                                    !shipment.detailServiceCharge
                                      ? t("tabInfo.total")
                                      : ""
                                  }
                                >
                                  {shipment.totalStoreCharge !== undefined &&
                                    shipment.totalStoreCharge
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Descriptions.Item>
                              </Descriptions>
                            </Skeleton>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              );
            })}
          <Row className="result-content-wrapper  total-row">
            <Col className="awb-result-content" offset={20} span={"auto"}>
              {t("tabInfo.total")}:&nbsp;
              {totalStoreCharge !== undefined &&
              totalServiceCharge !== undefined &&
              totalStoreCharge !== NaN &&
              totalServiceCharge !== NaN
                ? (Number(totalStoreCharge) + Number(totalServiceCharge))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalStoreCharge !== undefined
                ? totalStoreCharge
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalServiceCharge !== undefined
                ? totalServiceCharge.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : ""}
            </Col>
          </Row>
          <Row className="result-content-wrapper  total-row">
            <Col className="awb-result-content" span={"auto"}>
              {t("tabInfo.note")}
            </Col>
          </Row>
          <ChargeInfoControls setIsShowCharges={props.setIsShowCharges} />
        </>
      )}
      {billStatus === "E" && (
        <>
          <Row className="result-content-wrapper">
            <Col className="awb-result-content" span={24}>
              <Skeleton loading={loading}>
                <Table
                  columns={columnsGroup.export}
                  dataSource={dataSourceTable}
                  title={() => titleTable}
                  bordered
                  // scroll={{ x: 1300 }}
                  pagination={false}
                />
              </Skeleton>
            </Col>
          </Row>
          <Row className="result-title-wrapper">
            <Col span={24} className="awb-result-title">
              <p>{t("tabInfo.chargeInfo")}</p>
            </Col>
          </Row>
          <Row className="result-content-wrapper">
            <Col className="awb-result-content" span={24}>
              <Skeleton loading={loading}>
                <Descriptions
                  className="charges-result"
                  bordered
                  size={"small"}
                >
                  <Descriptions.Item label={t("tabInfo.flightNo")}>
                    {flightNo || ""}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  className="charges-result"
                  bordered
                  size={"small"}
                >
                  <Descriptions.Item label={t("tabInfo.storeType")}>
                    {storeType === "AVI"
                      ? t("tabInfo.goods.livestock")
                      : storeType === "COL"
                      ? t("tabInfo.goods.coolRoom")
                      : storeType === "DGR"
                      ? t("tabInfo.goods.dangerous")
                      : storeType === "RXB"
                      ? t("tabInfo.goods.explosives")
                      : storeType === "VAL"
                      ? t("tabInfo.goods.valuable")
                      : storeType === "GEN"
                      ? t("tabInfo.goods.general")
                      : storeType === "PER"
                      ? t("tabInfo.goods.perishable")
                      : storeType === "HUM"
                      ? t("tabInfo.goods.hum")
                      : t("tabInfo.goods.vulnerable")}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  className="charges-result"
                  bordered
                  size={"small"}
                >
                  <Descriptions.Item label={t("tabInfo.serviceType")}>
                    {serviceType === "AVI"
                      ? t("tabInfo.goods.livestock")
                      : serviceType === "COL"
                      ? t("tabInfo.goods.coolRoom")
                      : serviceType === "DGR"
                      ? t("tabInfo.goods.dangerous")
                      : serviceType === "RXB"
                      ? t("tabInfo.goods.explosives")
                      : serviceType === "VAL"
                      ? t("tabInfo.goods.valuable")
                      : serviceType === "GEN"
                      ? t("tabInfo.goods.general")
                      : serviceType === "PER"
                      ? t("tabInfo.goods.perishable")
                      : t("tabInfo.goods.vulnerable")}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  className="charges-result"
                  bordered
                  size={"small"}
                >
                  <Descriptions.Item label={t("tabInfo.recDate")}>
                    {dateBegin}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  className="charges-result"
                  bordered
                  size={"small"}
                >
                  <Descriptions.Item label={t("tabInfo.issueDate")}>
                    {dateEnd}
                  </Descriptions.Item>
                </Descriptions>
                {storeType === "GEN" ? (
                  <>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.sunday")}>
                        {parseInt(totalSunday) + parseInt(totalHoliday)}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.free")}>
                        {totalFreeDay}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.chargeDays")}>
                        {totalDayCharge}
                        {t("tabInfo.chargeDaysNote1")}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label="">
                        {parseInt(totalDayCharge) + parseInt(totalFreeDay)}
                        {t("tabInfo.chargeDaysNote2")}
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                ) : (
                  <>
                    <Descriptions
                      className="charges-result"
                      bordered
                      size={"small"}
                    >
                      <Descriptions.Item label={t("tabInfo.chargeDays")}>
                        {totalDayCharge}
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                )}
              </Skeleton>
            </Col>
          </Row>

          <Row className="result-content-wrapper">
            <Col className="awb-result-content" span={24}>
              {detailServiceCharge && (
                <Skeleton loading={loading}>
                  <Descriptions
                    column={4}
                    layout={"vertical"}
                    className="charges-detail-result"
                    bordered
                    size={"small"}
                  >
                    <Descriptions.Item
                      className="detail-label"
                      label={t("tabInfo.contentTitle")}
                    >
                      {t("tabInfo.serviceCharge")}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="detail-content"
                      label={t("tabInfo.descripe")}
                    >
                      {Parser(detailServiceCharge) || ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="detail-vat text-center"
                      label={t("tabInfo.tax")}
                    >
                      {`${serviceVatRate}%` || ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="text-right"
                      label={t("tabInfo.total")}
                    >
                      {totalServiceCharge &&
                        totalServiceCharge.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
              )}
              {detailStoreCharge && (
                <Skeleton loading={loading}>
                  <Descriptions
                    column={4}
                    layout={"vertical"}
                    className="charges-result"
                    bordered
                    size={"small"}
                  >
                    <Descriptions.Item
                      className="detail-label"
                      label={
                        !detailServiceCharge ? t("tabInfo.contentTitle") : ""
                      }
                    >
                      {t("tabInfo.storageCharge")}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="detail-content"
                      label={!detailServiceCharge ? t("tabInfo.descripe") : ""}
                    >
                      {Parser(detailStoreCharge) || ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="detail-vat text-center"
                      label={!detailServiceCharge ? t("tabInfo.tax") : ""}
                    >
                      {`${storeVatRate}%` || ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="text-right"
                      label={!detailServiceCharge ? t("tabInfo.total") : ""}
                    >
                      {totalStoreCharge &&
                        totalStoreCharge
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
              )}
            </Col>
          </Row>
          <Row className="result-content-wrapper  total-row">
            <Col className="awb-result-content" offset={20} span={"auto"}>
              {t("tabInfo.total")}:&nbsp;
              {totalStoreCharge !== undefined &&
              totalServiceCharge !== undefined
                ? (Number(totalStoreCharge) + Number(totalServiceCharge))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalStoreCharge !== undefined
                ? totalStoreCharge
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalServiceCharge !== undefined
                ? totalServiceCharge.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : ""}
            </Col>
          </Row>
          <Row className="result-content-wrapper  total-row">
            <Col className="awb-result-content" span={"auto"}>
              {t("tabInfo.note")}
            </Col>
          </Row>
          <ChargeInfoControls setIsShowCharges={props.setIsShowCharges} />
        </>
      )}
    </>
  );
};
export default ChargesTab;
