/* eslint-disable */
import { Row, Col, Button, Skeleton, Table, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  GET_AIRLINE_NONEDLV_LIST,
  GET_CUSTOMER_NAME,
} from "../../../config/webTrackApi";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { setPaymentInfo } from "../../../actions/payment";
import { useHistory } from "react-router";

const TabAirlinesInven = (props) => {
  const [tableDataRepo, setTableDataRepo] = useState();
  const [loading, setLoading] = useState();
  const { dataLogin } = useSelector((state) => state.userAwb);
  const airlineInput = useRef();
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    renderTableRepo();
  }, []);

  const renderTableRepo = async () => {
    try {
      let dateEnd = moment().format("DD/MM/YYYY");

      let startDate = moment().subtract(6, "months").format("DD/MM/YYYY");
      setLoading(true);

      let data = {
        STR_CONNECT: "sss",
        CARRIER_CODE: dataLogin.data.user_code.slice(2, 4).toUpperCase(),
        AGENT_CODE: "",
      };

      if (dataLogin.data.user_code === "admi") {
        data.CARRIER_CODE =
          airlineInput.current.state.value &&
          airlineInput.current.state.value.toUpperCase();
      } else {
        let carrierCode = dataLogin.data.user_code.slice(2, 4).toUpperCase();
        data.CARRIER_CODE = carrierCode;
      }
      const customerRes = await GET_CUSTOMER_NAME(
        data.CARRIER_CODE,
        dataLogin.token
      );
      if (customerRes.data != null && customerRes.data.mCol.length > 0) {
        const _airlineName = customerRes.data.mCol[0].C01_CUSTOMER_NAME;
        const _airlineCode = customerRes.data.mCol[0].C02_COD_CMP_CMS;
        let columns = [
          {
            title: `${t(
              "airlines.DeliveryTranform"
            )} ${_airlineCode}-${_airlineName}`,
            dataIndex: "name",
            key: "name",
            className: "detail-table",
            children: [
              {
                title:
                  t("agentReport.IncomingShipment") +
                  startDate +
                  t("agentReport.to") +
                  dateEnd,
                dataIndex: "names",
                key: "names",
                className: "detail-table",
                children: [
                  {
                    title: t("agentReport.MAWB"),
                    dataIndex: "name",
                    key: "name",
                    className: "head_tb_repo",
                    children: [
                      {
                        title: "MAWB",
                        dataIndex: "N04_AWB_NUM",
                        key: "N04_AWB_NUM",
                        className: "head_tb_repo",
                      },
                      {
                        title: "HAWB",
                        dataIndex: "N05_COD_HWB_NUM",
                        key: "N05_COD_HWB_NUM",
                        className: "head_tb_repo",
                      },
                    ],
                  },
                  {
                    title: t("agentReport.fightNo"),
                    dataIndex: "name",
                    key: "name",
                    className: "head_tb_repo",
                    children: [
                      {
                        title: t("agentReport.number"),
                        dataIndex: "N06_FLIGHT_NO",
                        key: "N06_FLIGHT_NO",
                        className: "head_tb_repo",
                      },
                      {
                        title: t("agentReport.Date"),
                        dataIndex: "N07_FLIGHT_DATE",
                        key: "N07_FLIGHT_DATE",
                        className: "head_tb_repo",
                      },
                    ],
                  },
                  {
                    title: "ORG/DES",
                    dataIndex: "N08_ROUTING",
                    key: "N08_ROUTING",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.Pieces"),
                    dataIndex: "N09_PCS",
                    key: "N09_PCS",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.GrossWeight"),
                    dataIndex: "N10_WGT",
                    key: "N10_WGT",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.NatureGoods"),
                    dataIndex: "N11_NATURE_OF_GOODS",
                    key: "N11_NATURE_OF_GOODS",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.Notification"),
                    dataIndex: "N12_NUM_NOTIFY",
                    key: "N12_NUM_NOTIFY",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.GoodStatus"),
                    dataIndex: "N13_IRR_INF",
                    key: "N13_IRR_INF",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.ShippingStatus"),
                    dataIndex: "N14_DLV_STS",
                    key: "N14_DLV_STS",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.ShipperInfo"),
                    dataIndex: "N15_SHP_NAME",
                    key: "N15_SHP_NAME",
                    className: "head_tb_repo",
                  },
                  {
                    title: t("agentReport.CneeInfo"),
                    dataIndex: "N16_CNE_NAME",
                    key: "N16_CNE_NAME",
                    className: "head_tb_repo",
                  },
                ],
              },
            ],
          },
        ];
        let dataSource = [];
        let url = "api/GET_AIRLINE_NONEDLV_LIST";
        await GET_AIRLINE_NONEDLV_LIST(url, data, dataLogin.token).then(
          (res) => {
            if (res.status === 200 && res.data != "" && res.data !== null) {
              res.data.mCol.map((dt, index) => {
                dataSource.push({
                  Key: index,
                  N04_AWB_NUM: dt.N04_AWB_NUM,
                  N05_COD_HWB_NUM: dt.N05_COD_HWB_NUM,
                  N06_FLIGHT_NO: dt.N06_FLIGHT_NO,
                  N07_FLIGHT_DATE: dt.N07_FLIGHT_DATE,
                  N08_ROUTING: dt.N08_ROUTING,
                  N09_PCS: dt.N09_PCS,
                  N10_WGT: dt.N10_WGT,
                  N11_NATURE_OF_GOODS: dt.N11_NATURE_OF_GOODS,
                  N12_NUM_NOTIFY: "L" + dt.N12_NUM_NOTIFY,
                  N13_IRR_INF: dt.N13_IRR_INF,
                  N14_DLV_STS: dt.N14_DLV_STS,
                  N15_SHP_NAME: dt.N15_SHP_NAME,
                  N16_CNE_NAME: dt.N16_CNE_NAME,
                });
              });
              let date =
                props.today.getDate() +
                "/" +
                (props.today.getMonth() + 1) +
                "/" +
                props.today.getFullYear() +
                " " +
                props.today.getHours() +
                ":" +
                props.today.getMinutes() +
                ":" +
                props.today.getSeconds();
              setTableDataRepo(
                <>
                  <Table
                    className="table-bill"
                    dataSource={dataSource}
                    columns={columns}
                    bordered={"true"}
                    // scroll={{ x: 1300 }}
                    pagination={{ position: "bottomRight", pageSize: 1000 }}
                    summary={() => (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={13}>
                            {t("agentReport.total")} {res.data.mCol.length}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={13}>
                            {t("agentReport.Time")} {date}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )}
                  />
                  <table width="1000">
                    <tr>
                      <td>{t("agentReport.Note")}</td>
                      <td colSpan="8">
                        <strong>L0</strong> : {t("agentReport.L0")}
                        <strong>L1</strong> : {t("agentReport.L1")}
                        <strong> L2</strong> :{t("agentReport.L2")}
                      </td>
                    </tr>
                  </table>
                </>
              );
            }
            setLoading(false);
          }
        );
      }
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("airlines.listDelivery")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Col span={24} className="control">
            <Row offset={5} className="control-button-group-wrapper">
              {dataLogin.data.user_code == "admi" ? (
                <>
                  <Col offset={7}>
                    <Input
                      placeholder={"Carrier code"}
                      ref={airlineInput}
                    ></Input>
                  </Col>
                </>
              ) : (
                <>
                  <Col offset={10}> </Col>
                </>
              )}
              <Col>
                <Button
                  style={{ width: "fit-content" }}
                  htmlType={"submit"}
                  onClick={renderTableRepo}
                >
                  {t("airlines.refresh")}
                </Button>
              </Col>
              <Col>
                <Button onClick={handlePrint} style={{ width: "fit-content" }}>
                  {t("airlines.print")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row ref={componentRef} className="result-wrapper">
          <Row className="result-title-wrapper">
            <Col span={24} className="result-title">
              <h5>{t("airlines.list")}</h5>
            </Col>
          </Row>
          <Skeleton loading={loading}>
            <Row className="result-wrapper">
              <Col span={24} className="result">
                {tableDataRepo}
              </Col>
            </Row>
          </Skeleton>
        </Row>
      </Row>
    </div>
  );
};

export default TabAirlinesInven;
