/* eslint-disable */

import { useEffect, useRef, useState } from "react";
import {
  GET_MAWB_CTC_INF,
  GetBillDetail,
  GET_EXP_ACCEPTANCE,
  GET_EXP_BOOKING_INF,
  GET_EXP_SHIPMENT_INF,
  GET_SHIPMENT_INF,
  GET_CUSTOMER_NAME,
  GET_HAWB_DOC_INF,
} from "../config/webTrackApi";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Skeleton,
  message,
  notification,
  Input,
} from "antd";
import ChargesTab from "./ChargesTab";
import AcceptanceResult from "./ExportResult/AcceptanceResult";
import CustomsResult from "./ExportResult/CustomsResult";
import ShipmentInfo from "./ShipmentInfo";
import ImportResultNoDetails from "./ImportResult/ImportResultNoDetails";
import ImportResultWithDetails from "./ImportResult/ImportResultWithDetails";
import { setMAWB } from "../actions/MAWB";
import React from "react";
import TabInfoControls from "./TabInfoControls";
import ModalGetAccount from "./tracking/TabInfo/ModalGetAccount";
import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../actions/userAWB";
import { setPaymentInfo } from "../actions/payment";

const TabInfo = () => {
  const [loading, setLoading] = useState();
  const [acceptanceDate, setAcceptanceDate] = useState();
  const [bookingInfo, setBookingInfo] = useState();
  const [billStatus, setBillStatus] = useState();
  const [billType, setBillType] = useState();
  const [customer, setCustomer] = useState({ C02_COD_CMP_CMS: "" });
  const [dataTableInfoBill, setDataTableInfoBill] = useState();
  const [expInfo, setExpInfo] = useState();
  const [isShowCharges, setIsShowCharges] = useState(false);
  const [ableToGetAcc, setAbleToGetAcc] = useState(false);
  const [visibleModalGetAcc, setVisibleModalGetAcc] = useState(false);
  const [HAWBs, setHAWBs] = useState();
  const [titleCustoms, setTitleCustoms] = useState("");
  const [customsInfo, setCustomsInfo] = useState();
  const [isRowSelected, setIsRowSelected] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [titleShipmentInfo, setTitleShipmentInfo] = useState("");
  const [columnsTableShipment, setColumnsTableShipment] = useState([]);
  const [dataShipmentInfo, setDataShipmentInfo] = useState([]);
  const [titleAcceptance, setTitleAcceptance] = useState("");
  const { langId } = useSelector((state) => state.menu);
  const { MAWB } = useSelector((state) => state.mawb);
  const { dataLogin } = useSelector((state) => state.userAwb);
  const [form] = useForm();
  const [title, setTitle] = useState(
    langId === "2"
      ? "HÃY NHẬP SỐ KHÔNG VẬN ĐƠN ĐỂ TIẾP TỤC"
      : "PLEASE INPUT MAWB AND NEXT"
  );
  const { t } = useTranslation("common");
  const [tableDataBill, setTableDataBill] = useState();
  const firstParam = useRef();
  const secondParam = useRef();
  const [carrierCode, SetCarrierCode] = useState('');

  const errorParams = () => {
    message.error(
      langId === "2"
        ? "Bạn phải nhập đầy đủ thông tin."
        : "You have to input MAWB"
    );
  };
  const clearResult = () => {
    setTitleShipmentInfo("");
    setTitleCustoms("");
    setTitleAcceptance("");
    SetCarrierCode("");
    setTitle(
      langId === "2"
        ? "HÃY NHẬP SỐ KHÔNG VẬN ĐƠN ĐỂ TIẾP TỤC"
        : "PLEASE INPUT MAWB AND NEXT"
    );
  };
  const errorBill = () => {
    message.error(langId === "2" ? "Mã vận đơn không hợp lệ." : "Invalid MAWB");
    setTableDataBill(<></>);
  };
  const limitInput = (e) => {
    if (e.target.value.length > e.target.maxLength)
      switch (e.target.name) {
        case "awbLast":
          form.setFieldsValue({
            awbLast: e.target.value.slice(0, e.target.maxLength),
          });
          break;
        case "awbFirst":
          form.setFieldsValue({
            awbFirst: e.target.value.slice(0, e.target.maxLength),
          });
          break;
        default:
          break;
      }
  };
  const submitAwb = async (values) => {
    try {
      if (values?.awbFirst || values?.awbLast) {
        setIsShowCharges(false);
        setLoading(true);
        let _billStatus;
        let _billType;
        let _inforBill;
        if (
          firstParam.current === undefined ||
          secondParam.current === undefined ||
          secondParam.current.props.value === "" ||
          firstParam.current.props.value === ""
        ) {
          if (
            firstParam.current.props.value.length < 3 ||
            secondParam.current.props.value.length < 8
          ) {
            clearResult();
            errorParams();
          }
        } else {
          dispatch(
            setMAWB({
              ...MAWB,
              first: firstParam.current.props.value,
              last: secondParam.current.props.value,
            })
          );
          localStorage.setItem(
            "MAWB",
            JSON.stringify({
              ...MAWB,
              first: firstParam.current.props.value,
              last: secondParam.current.props.value,
            })
          );
          let columns = [
            {
              className: "head-awb",
              title: t("tabInfo.org"),
              dataIndex: "B03_ORG",
              key: "B03_ORG",
            },
            {
              className: "head-awb",
              title: t("tabInfo.des"),
              dataIndex: "B04_DES",
              key: "B04_DES",
            },
            {
              className: "head-awb",
              title: t("tabInfo.pcs"),
              dataIndex: "B07_PCS",
              key: "B07_PCS",
            },
            {
              className: "head-awb",
              title: t("tabInfo.weight"),
              dataIndex: "B08_WEIGHT",
              key: "B08_WEIGHT",
            },
          ];
          let header =
            t("tabInfo.mawbNo") +
            firstParam.current.props.value +
            "-" +
            secondParam.current.props.value;
          let dataSource = [];
          let params = {
            STR_CONNECT: "sss",
            AWB_PFX: firstParam.current.props.value,
            AWB_NO: secondParam.current.props.value,
            HAWB_NO: "*",
            FL_NO: "CX0767",
            FL_DATE: "3/31/2013",
          };
          let url = "api/GET_MAWB_INF";
          try {
            const billDetailRes = await GetBillDetail(
              url,
              params,
              dataLogin.token
            );
            if (billDetailRes.data.mCol.length === 0) {
              clearResult();
              errorBill();
              return;
            }
            if (
              billDetailRes.status == 200 &&
              billDetailRes.data != "" &&
              billDetailRes.data.mCol.length >= 1
            ) {
              _billStatus = billDetailRes.data.mCol[0].B09_FLG_IMP_EXP;
              _billType = billDetailRes.data.mCol[0].B02_FLG_HDL;
              setBillStatus(_billStatus);
              setBillType(_billType);
              SetCarrierCode(billDetailRes.data.mCol[0].B11_CARRIER_CODE);
              if (_billStatus === "E") setTitle(t("tabInfo.exportTitle"));
              if (_billStatus === "I" && _billType === "M")
                setTitle(t("tabInfo.importTitle"));
              if (_billStatus === "I" && _billType === "H")
                setTitle(t("tabInfo.hawbTitle"));
              billDetailRes.data.mCol.map((dt, index) => {
                dataSource.push({
                  key: index,
                  B03_ORG: dt.B03_ORG,
                  B04_DES: dt.B04_DES,
                  B07_PCS: dt.B07_PCS,
                  B08_WEIGHT: dt.B08_WEIGHT + " (kg)",
                });
              });

              const billCtcRes = await GET_MAWB_CTC_INF(
                "/api/GET_MAWB_CTC_INF",
                params,
                dataLogin.token
              );
              if (
                billCtcRes.status == 200 &&
                billCtcRes.data != "" &&
                billCtcRes.data.mCol.length
              ) {
                if (_billType !== "H") {
                  renderTable(
                    columns,
                    header,
                    dataSource,
                    billCtcRes.data.mCol
                  );
                  setDataTableInfoBill(dataSource);
                  _inforBill = billCtcRes.data.mCol[0];
                }
                if (_billType === "H") {
                  renderTable(columns, header, dataSource);
                  setDataTableInfoBill(dataSource);
                  _inforBill = billCtcRes.data.mCol[0];
                } else {
                  renderTable(
                    columns,
                    header,
                    dataSource,
                    billCtcRes.data.mCol
                  );
                  setDataTableInfoBill(dataSource);
                  _inforBill = billCtcRes.data.mCol[0];
                }
              }
            }
            setLoading(false);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              notification.error({
                style: { marginRight: "-50%" },
                message: t("home.sessionNoti"),
              });

              dispatch(checkinUser({}));
              dispatch(setPaymentInfo(undefined));
              dispatch(userAwbLogout());
              localStorage.removeItem("userAWB");
              localStorage.removeItem("checkin");
              localStorage.removeItem("pInfo");
              history.push("/");
            } else
              notification["error"]({
                message: "Lỗi",
                description: "Mã vận đơn không hợp lệ.",
                style: { marginRight: "-50%" },
              });
          } finally {
            setLoading(false);
          }
          if (_billStatus === "E") {
            setAbleToGetAcc(false);
            GET_EXP_ACCEPTANCE(
              firstParam.current.props.value,
              secondParam.current.props.value
            ).then((res) => {
              if (res.status == 200 && res.data != "" && res.data.mCol.length) {
                setAcceptanceDate(
                  moment(res.data.mCol[0].ECA18_ACCP_DATE).format("LL HH:mm")
                );
                setExpInfo({
                  ...expInfo,
                  acceptanceResult: res.data.mCol[0],
                });
              }
            });
            let _bookingInfo = "";
            let _flightDate = ``;

            await GET_EXP_BOOKING_INF(
              firstParam.current.props.value,
              secondParam.current.props.value
            ).then((res) => {
              if (res.status == 200 && res.data != "" && res.data.mCol.length) {
                setBookingInfo(
                  `${
                    res.data.mCol[0].EBK04_COD_FLT_CAR +
                    res.data.mCol[0].EBK05_COD_FLT_NUM
                  } ${moment(res.data.mCol[0].EBK15_DAT_STD).format("LL")}`
                );
                _bookingInfo = ` ${
                  res.data.mCol[0].EBK04_COD_FLT_CAR +
                  res.data.mCol[0].EBK05_COD_FLT_NUM
                } ${moment(res.data.mCol[0].EBK15_DAT_STD).format("LL")}`;
                setExpInfo({
                  ...expInfo,
                  bookingResult: res.data.mCol[0],
                });
                setTitleAcceptance(t("tabInfo.acceptanceTitle"));
              }
            });
            await GET_EXP_SHIPMENT_INF(
              firstParam.current.props.value,
              secondParam.current.props.value
            ).then((res) => {
              if (res.status == 200 && res.data != "" && res.data.mCol.length) {
                if (res.data.mCol[0].ES23_RCV_NAME === "NIL")
                  setCustomsInfo(`${t("tabInfo.customsContent.incomplete")}`);
                else
                  setCustomsInfo(
                    `${t("tabInfo.customsContent.complete")} ${moment(
                      res.data.mCol[0].ES23_RCV_NAME
                    ).format("LL HH:mm")}`
                  );
                setTitleCustoms(t("tabInfo.customsTitle"));
                setTitleShipmentInfo(t("tabInfo.shipmentTitle"));
                const columns = [
                  [
                    {
                      className: "head-awb",
                      title: t("tabInfo.flightNo"),
                      dataIndex: "ES24_DLV_TYPE",
                      key: "ES24_DLV_TYPE",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.ATD"),
                      dataIndex: "ES17_FLT_ATA",
                      key: "ES17_FLT_ATA",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.status"),
                      dataIndex: "ES20_GOODS_ST",
                      key: "ES20_GOODS_ST",
                    },
                  ],
                  [
                    {
                      className: "head-awb",
                      title: t("tabInfo.no"),
                      dataIndex: "index",
                      key: "index",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.flightNo"),
                      dataIndex: "ES24_DLV_TYPE",
                      key: "ES24_DLV_TYPE",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.ATD"),
                      dataIndex: "ES17_FLT_ATA",
                      key: "ES17_FLT_ATA",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.pcs"),
                      dataIndex: "ES05_QTY_SHP_PCS",
                      key: "ES05_QTY_SHP_PCS",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.weight"),
                      dataIndex: "ES06_QTY_SHP_WGT",
                      key: "ES06_QTY_SHP_WGT",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.status"),
                      dataIndex: "ES20_GOODS_ST",
                      key: "ES20_GOODS_ST",
                    },
                  ],
                ];
                if (res.data.mCol.length !== 1) {
                  let data = [];
                  let goodsStatus = "";
                  setExpInfo({
                    ...expInfo,
                    shipmentResult: res.data.mCol,
                  });
                  res.data.mCol.map((dt, index) => {
                    if (
                      dt.ES20_GOODS_ST === "RCS" ||
                      dt.ES20_GOODS_ST === "BKD" ||
                      dt.ES20_GOODS_ST === "AS"
                    )
                      if (
                        moment(dt.ES22_DLV_DATE).format("LL") !== "Invalid date"
                      )
                        goodsStatus = `${t("tabInfo.statusContent.book")} ${
                          dt.ES24_DLV_TYPE
                        }  ${moment(dt.ES22_DLV_DATE).format("LL")}`;
                      else
                        goodsStatus = `${t(
                          "tabInfo.statusContent.book"
                        )} ${_bookingInfo}`;
                    else if (dt.ES20_GOODS_ST === "MAN")
                      goodsStatus = t("tabInfo.statusContent.manifested");
                    else if (dt.ES20_GOODS_ST === "RET")
                      goodsStatus = t("tabInfo.statusContent.return");
                    else if (dt.ES20_GOODS_ST === "OFD")
                      goodsStatus = t("tabInfo.statusContent.offload");
                    else if (dt.ES20_GOODS_ST === "DEP")
                      goodsStatus = `${t("tabInfo.statusContent.onFlight")} ${
                        dt.ES24_DLV_TYPE
                      } ${moment(dt.ES22_DLV_DATE).format("LL")}`;
                    _flightDate =
                      moment(dt.ES17_FLT_ATA).format("LL HH:mm") !==
                      "Invalid date"
                        ? moment(dt.ES17_FLT_ATA).format("LL HH:mm")
                        : "";
                    data.push({
                      key: index,
                      index: ++index,
                      ES06_QTY_SHP_WGT: dt.ES06_QTY_SHP_WGT + "(kg)",
                      ES05_QTY_SHP_PCS: dt.ES05_QTY_SHP_PCS,
                      ES24_DLV_TYPE: dt.ES24_DLV_TYPE,
                      ES17_FLT_ATA: _flightDate,
                      ES03_COD_AWB_SFX: dt.ES03_COD_AWB_SFX,
                      ES20_GOODS_ST: goodsStatus,
                    });
                  });
                  setDataShipmentInfo(data);
                  setColumnsTableShipment(columns[1]);
                } else {
                  let data = [];
                  let goodsStatus = "";
                  setExpInfo({
                    ...expInfo,
                    shipmentResult: res.data.mCol,
                  });
                  res.data.mCol.map((dt, index) => {
                    if (
                      dt.ES20_GOODS_ST === "RCS" ||
                      dt.ES20_GOODS_ST === "BKD" ||
                      dt.ES20_GOODS_ST === "AS"
                    )
                      if (
                        moment(dt.ES22_DLV_DATE).format("LL") !== "Invalid date"
                      )
                        goodsStatus = `${t("tabInfo.statusContent.book")} ${
                          dt.ES24_DLV_TYPE
                        }  ${moment(dt.ES22_DLV_DATE).format("LL")}`;
                      else
                        goodsStatus = `${t(
                          "tabInfo.statusContent.book"
                        )} ${_bookingInfo}`;
                    if (dt.ES20_GOODS_ST === "MAN")
                      goodsStatus = t("tabInfo.statusContent.manifested");
                    if (dt.ES20_GOODS_ST === "RET")
                      goodsStatus = t("tabInfo.statusContent.return");
                    if (dt.ES20_GOODS_ST === "OFD")
                      goodsStatus = t("tabInfo.statusContent.offload");
                    if (dt.ES20_GOODS_ST === "DEP")
                      goodsStatus = `${t("tabInfo.statusContent.onFlight")} ${
                        dt.ES24_DLV_TYPE
                      } ${moment(dt.ES22_DLV_DATE).format("LL")}`;
                    _flightDate =
                      moment(dt.ES17_FLT_ATA).format("LL HH:mm") !==
                      "Invalid date"
                        ? moment(dt.ES17_FLT_ATA).format("LL HH:mm")
                        : "";

                    data.push({
                      key: index,
                      ES24_DLV_TYPE: dt.ES24_DLV_TYPE,
                      ES17_FLT_ATA: _flightDate,
                      ES03_COD_AWB_SFX: dt.ES03_COD_AWB_SFX,
                      ES20_GOODS_ST: goodsStatus,
                    });
                  });
                  setDataShipmentInfo(data);
                  setColumnsTableShipment(columns[0]);
                }
              }
            });
          }
          if (_billStatus === "I" && _billType === "M") {
            setAbleToGetAcc(true);
            GET_SHIPMENT_INF(
              firstParam.current.props.value,
              secondParam.current.props.value,
              "",
              dataLogin.token
            ).then((res) => {
              if (res.status == 200 && res.data != "" && res.data.mCol.length) {
                setTitleShipmentInfo(
                  res.data.mCol.length === 0
                    ? `${t("tabInfo.shipmentTitle")}: Chưa có hàng`
                    : t("tabInfo.shipmentTitle")
                );
                const columns = [
                  [
                    {
                      className: "head-awb",
                      title: t("tabInfo.no"),
                      dataIndex: "index",
                      key: "index",
                      visible: false,
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.flightNo"),
                      dataIndex: "S09_COD_SHP_CAR",
                      key: "S09_COD_SHP_CAR",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.ATA"),
                      dataIndex: "S17_FLT_ATA",
                      key: "S17_FLT_ATA",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.pcs"),
                      dataIndex: "S05_QTY_SHP_PCS",
                      key: "S05_QTY_SHP_PCS",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.weight"),
                      dataIndex: "S06_QTY_SHP_WGT",
                      key: "S06_QTY_SHP_WGT",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.reWeight"),
                      dataIndex: "S18_SHP_RE_WGT",
                      key: "S18_SHP_RE_WGT",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.status"),
                      dataIndex: "S20_GOODS_ST",
                      key: "S20_GOODS_ST",
                    },
                  ],
                  [
                    {
                      className: "head-awb",
                      title: t("tabInfo.flightNo"),
                      dataIndex: "S09_COD_SHP_CAR",
                      key: "S09_COD_SHP_CAR",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.ATA"),
                      dataIndex: "S17_FLT_ATA",
                      key: "S17_FLT_ATA",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.pcs"),
                      dataIndex: "S05_QTY_SHP_PCS",
                      key: "S05_QTY_SHP_PCS",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.weight"),
                      dataIndex: "S06_QTY_SHP_WGT",
                      key: "S06_QTY_SHP_WGT",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.reWeight"),
                      dataIndex: "S18_SHP_RE_WGT",
                      key: "S18_SHP_RE_WGT",
                    },
                    {
                      className: "head-awb",
                      title: t("tabInfo.status"),
                      dataIndex: "S20_GOODS_ST",
                      key: "S20_GOODS_ST",
                    },
                  ],
                ];
                if (res.data.mCol.length > 1) {
                  let data = [];
                  let goodsStatus = "";

                  res.data.mCol.map((dt, index) => {
                    if (dt.S20_GOODS_ST == "DLV")
                      goodsStatus = `${t(
                        "tabInfo.statusContent.deliveredTo"
                      )} ${dt.S23_RCV_NAME} ${t(
                        "tabInfo.statusContent.date"
                      )} ${moment(dt.S22_DLV_DATE).format("LL")}`;
                    else if (
                      (dt.S21_CHK_IN_DATE === "" &&
                        dt.S04_FLG_SHP_RDY === "Y" &&
                        dt.S20_GOODS_ST !== "DLV" &&
                        (dt.S27_NUM_NOTIFY == "0" ||
                          dt.S27_NUM_NOTIFY == "")) ||
                      (dt.S21_CHK_IN_DATE == "" &&
                        dt.S20_GOODS_ST == "RCF" &&
                        (dt.S27_NUM_NOTIFY == "0" || dt.S27_NUM_NOTIFY == ""))
                    )
                      goodsStatus = t("tabInfo.statusContent.store");
                    else if (
                      dt.S21_CHK_IN_DATE == "" &&
                      dt.S04_FLG_SHP_RDY == "Y" &&
                      dt.S20_GOODS_ST != "DLV" &&
                      (dt.S27_NUM_NOTIFY == "1" ||
                        dt.S27_NUM_NOTIFY == "2" ||
                        dt.S27_NUM_NOTIFY == "3")
                    )
                      langId === "2"
                        ? (goodsStatus = `Hàng đã được thông báo lần ${dt.S27_NUM_NOTIFY}`)
                        : (goodsStatus = `Notified ${dt.S27_NUM_NOTIFY} time shipment`);
                    else if (
                      (dt.S21_CHK_IN_DATE !== "" &&
                        dt.S20_GOODS_ST !== "DLV") ||
                      dt.S20_GOODS_ST == "AR"
                    )
                      goodsStatus = t("tabInfo.statusContent.notDeli");
                    else goodsStatus = t("tabInfo.statusContent.contactTCS");
                    data.push({
                      key: index,
                      index: ++index,
                      S06_QTY_SHP_WGT: dt.S06_QTY_SHP_WGT + "(kg)",
                      S05_QTY_SHP_PCS: dt.S05_QTY_SHP_PCS,
                      S18_SHP_RE_WGT:
                        dt.S18_SHP_RE_WGT !== ""
                          ? dt.S18_SHP_RE_WGT + "(kg)"
                          : "",
                      S09_COD_SHP_CAR: dt.S09_COD_SHP_CAR + dt.S10_COD_SHP_FLT,
                      S17_FLT_ATA: moment(dt.S17_FLT_ATA).format("LL HH:mm"),
                      S03_COD_AWB_SFX: dt.S03_COD_AWB_SFX,
                      S20_GOODS_ST: goodsStatus,
                    });
                  });
                  setDataShipmentInfo(data);
                  setColumnsTableShipment(columns[0]);
                } else if (res.data.mCol.length === 1) {
                  let data = [];
                  let goodsStatus = "";

                  res.data.mCol.map((dt, index) => {
                    if (dt.S20_GOODS_ST === "DLV")
                      goodsStatus = `${t(
                        "tabInfo.statusContent.deliveredTo"
                      )} ${dt.S23_RCV_NAME} ${t(
                        "tabInfo.statusContent.date"
                      )} ${moment(dt.S22_DLV_DATE).format("LL")}`;
                    else if (
                      (dt.S21_CHK_IN_DATE === "" &&
                        dt.S04_FLG_SHP_RDY === "Y" &&
                        dt.S20_GOODS_ST !== "DLV" &&
                        (dt.S27_NUM_NOTIFY == "0" ||
                          dt.S27_NUM_NOTIFY == "")) ||
                      (dt.S21_CHK_IN_DATE == "" &&
                        dt.S20_GOODS_ST == "RCF" &&
                        (dt.S27_NUM_NOTIFY == "0" || dt.S27_NUM_NOTIFY == ""))
                    )
                      goodsStatus = t("tabInfo.statusContent.store");
                    else if (
                      dt.S21_CHK_IN_DATE == "" &&
                      dt.S04_FLG_SHP_RDY == "Y" &&
                      dt.S20_GOODS_ST != "DLV" &&
                      (dt.S27_NUM_NOTIFY == "1" ||
                        dt.S27_NUM_NOTIFY == "2" ||
                        dt.S27_NUM_NOTIFY == "3")
                    )
                      langId === "2"
                        ? (goodsStatus = `Hàng đã được thông báo lần ${dt.S27_NUM_NOTIFY}`)
                        : (goodsStatus = `Notified ${dt.S27_NUM_NOTIFY} time shipment`);
                    else if (
                      (dt.S21_CHK_IN_DATE !== "" &&
                        dt.S20_GOODS_ST !== "DLV") ||
                      dt.S20_GOODS_ST == "AR"
                    )
                      goodsStatus = t("tabInfo.statusContent.notDeli");
                    else goodsStatus = t("tabInfo.statusContent.contactTCS");
                    data.push({
                      key: index,
                      S06_QTY_SHP_WGT: dt.S06_QTY_SHP_WGT + "(kg)",
                      S05_QTY_SHP_PCS: dt.S05_QTY_SHP_PCS,
                      S18_SHP_RE_WGT:
                        dt.S18_SHP_RE_WGT !== ""
                          ? dt.S18_SHP_RE_WGT + "(kg)"
                          : "",
                      S09_COD_SHP_CAR: dt.S09_COD_SHP_CAR + dt.S10_COD_SHP_FLT,
                      S17_FLT_ATA: moment(dt.S17_FLT_ATA).format("LL HH:mm"),
                      S03_COD_AWB_SFX: dt.S03_COD_AWB_SFX,
                      S20_GOODS_ST: goodsStatus,
                    });
                  });
                  setDataShipmentInfo(data);
                  setColumnsTableShipment(columns[1]);
                }
              }
            });
          }
          if (_billType === "H" && _billStatus === "I") {
            setAbleToGetAcc(false);
            setTitleShipmentInfo("abc");
            GET_CUSTOMER_NAME(
              _inforBill && _inforBill.B05_FL_NO,
              dataLogin.token
            ).then((res) => {
              if (res.data.mCol.length > 0) setCustomer(res.data.mCol[0]);
            });
            GET_HAWB_DOC_INF(
              firstParam.current.props.value,
              secondParam.current.props.value,
              "*",
              dataLogin.token
            ).then((res) => {
              setHAWBs(res.data.mCol);
            });
          }
        }
      } else {
        clearResult();
        errorParams();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    } finally {
      setLoading(false);
    }
  };
  const renderTable = (columns, header, dataSource, info) => {
    setTableDataBill(
      <Table
        className="table-bill"
        dataSource={dataSource}
        columns={columns}
        bordered
        scroll={{ x: 700 }}
        title={() => header}
        pagination={false}
        summary={() => {
          return (
            info && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    {t("tabInfo.shipper")}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={3}>
                    {info[0].C03_SHP_NAME}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    {t("tabInfo.address")}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={3}>
                    {info[0].C04_SHP_ADDR}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    {t("tabInfo.consignee")}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={3}>
                    {info[0].C01_CNE_NAME}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    {t("tabInfo.address")}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={3}>
                    {info[0].C02_CNE_ADDR}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          );
        }}
      />
    );
  };
  const autoTab = (e) => {
    e.target.value.length === 3 && secondParam.current.focus();
  };
  const tailLayout = {
    wrapperCol: { offset: 1, span: 16 },
  };

  useEffect(() => {
    form.setFieldsValue({
      awbFirst: MAWB.first,
      awbLast: MAWB.last,
    });
    if (MAWB.isSubmitForm) {
      form.submit();
      dispatch(
        setMAWB({
          ...MAWB,
          first: MAWB.first,
          last: MAWB.last,
          isSubmitForm: false,
        })
      );
    }
  }, [MAWB.isSubmitForm]);

  useEffect(() => {
    form.setFieldsValue({
      awbFirst: MAWB.first,
      awbLast: MAWB.last,
    });
    form.submit();
  }, []);

  return (
    <Row className="awb-tab-content">
      <Row className="control-wrapper">
        <Col span={24} className="control">
          <Form
            className="form-awb"
            onFinish={submitAwb}
            layout={"inline"}
            form={form}
            autoComplete="off"
          >
            <Form.Item name="awbFirst" label={t("header.billInfo")}>
              <Input
                type="number"
                maxLength={3}
                onKeyUp={autoTab}
                name="awbFirst"
                onChange={limitInput}
                className="awb-first"
                ref={firstParam}
              />
            </Form.Item>
            <Form.Item name="awbLast">
              <Input
                type="number"
                maxLength={8}
                name="awbLast"
                onChange={limitInput}
                className="awb-last"
                ref={secondParam}
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                loading={loading}
                style={{ width: "fit-content" }}
                htmlType="submit"
              >
                {t("header.go")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <ModalGetAccount
        visible={visibleModalGetAcc}
        setVisible={setVisibleModalGetAcc}
      />
      <Row className="result-wrapper">
        {(billStatus === "E" || billStatus === "I") && isShowCharges ? (
          <ChargesTab
            setIsShowCharges={setIsShowCharges}
            expInfo={expInfo}
            dataTable={dataTableInfoBill}
            HAWBs={HAWBs}
            billStatus={billStatus}
            billType={billType}
          />
        ) : (
          <>
            <Row className="result-title-wrapper">
              <Col span={24} className="awb-result-title">
                <h5>{title}</h5>
              </Col>
            </Row>
            <Row className="result-content-wrapper">
              <Col className="awb-result-content" span={24}>
                <Skeleton loading={loading}>{tableDataBill}</Skeleton>
              </Col>
            </Row>
          </>
        )}
        {billStatus === "E" &&
          titleAcceptance !== "" &&
          titleShipmentInfo !== "" &&
          titleCustoms !== "" &&
          !isShowCharges && (
            <>
              <AcceptanceResult
                acceptanceDate={acceptanceDate}
                loading={loading}
                titleAcceptance={titleAcceptance}
                bookingInfo={bookingInfo}
              />
              <CustomsResult
                loading={loading}
                titleCustoms={titleCustoms}
                customsInfo={customsInfo}
              />
              <ShipmentInfo
                loading={loading}
                titleShipmentInfo={titleShipmentInfo}
                dataShipmentInfo={dataShipmentInfo}
                columnsTableShipment={columnsTableShipment}
                setIsRowSelected={setIsRowSelected}
              />
              <TabInfoControls
                isRowSelected={isRowSelected}
                billType={billType}
                setIsShowCharges={setIsShowCharges}
                setVisibleModalGetAcc={setVisibleModalGetAcc}
                ableToGetAcc={ableToGetAcc}
              />
            </>
          )}
        {billStatus === "I" &&
        titleShipmentInfo !== "" &&
        billType === "M" &&
        !isShowCharges ? (
          <>
            <ShipmentInfo
              loading={loading}
              titleShipmentInfo={titleShipmentInfo}
              dataShipmentInfo={dataShipmentInfo}
              columnsTableShipment={columnsTableShipment}
              setIsRowSelected={setIsRowSelected}
            />
            <TabInfoControls
              isRowSelected={isRowSelected}
              billType={billType}
              setIsShowCharges={setIsShowCharges}
              setVisibleModalGetAcc={setVisibleModalGetAcc}
              ableToGetAcc={ableToGetAcc}
            />
          </>
        ) : billType === "H" &&
          dataLogin.data.user_code !== "admi" &&
          carrierCode && !dataLogin.data.user_code.toLocaleUpperCase().includes(carrierCode.toLocaleUpperCase()) &&
          !isShowCharges &&
          title !== "HÃY NHẬP SỐ KHÔNG VẬN ĐƠN ĐỂ TIẾP TỤC" &&
          title !== "PLEASE INPUT MAWB AND NEXT" &&
          dataLogin.data.user_code !== customer.C02_COD_CMP_CMS ? (
          <ImportResultNoDetails customer={customer} />
        ) : (
          title !== "HÃY NHẬP SỐ KHÔNG VẬN ĐƠN ĐỂ TIẾP TỤC" &&
          title !== "PLEASE INPUT MAWB AND NEXT" &&
          billType === "H" &&
          titleShipmentInfo !== "" &&
          (dataLogin.data.user_code === "admi" ||
            (carrierCode && dataLogin.data.user_code.toLocaleUpperCase().includes(carrierCode.toLocaleUpperCase())) ||
            dataLogin.data.user_code === customer.C02_COD_CMP_CMS) &&
          !isShowCharges && (
            <>
              <ImportResultWithDetails
                loading={loading}
                dataShipmentInfo={dataShipmentInfo}
                columnsTableShipment={columnsTableShipment}
                HAWBs={HAWBs}
                firstParam={firstParam.current.props.value}
                secondParam={secondParam.current.props.value}
                setIsRowSelected={setIsRowSelected}
              />
              <TabInfoControls
                allowCheckin={
                  dataLogin.data.user_code === customer.C02_COD_CMP_CMS
                }
                billType={billType}
                isRowSelected={isRowSelected}
                setIsShowCharges={setIsShowCharges}
                setVisibleModalGetAcc={setVisibleModalGetAcc}
                ableToGetAcc={ableToGetAcc}
              />
            </>
          )
        )}
      </Row>
    </Row>
  );
};
export default TabInfo;
