/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Table,
  Form,
  Input,
  Button,
  notification,
  Modal,
  Space,
} from "antd";
import "./css/awb.css";
import { useForm } from "antd/lib/form/Form";
import QRCode from "qrcode.react";
import { BarcodeToPrint } from "./BarcodeToPrint";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IMP_WEB_CHK_CRE_BIL_CHG_OBJ } from "../../config/apiConfig";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { QrCodeToPrint } from "./QrCodeToPrint";
import { checkinUser } from "../../actions/userAWB";
import { setPaymentInfo } from "../../actions/payment";
import { userAwbLogout } from "./../../actions/userAWB";
import { useHistory } from "react-router";
import useWindowSize from "./../../helpers/useWindowSize";

const { confirm, warning } = Modal;

export default function AWB({ loadData, info, status, chargeInfo, ...props }) {
  const [form] = useForm();
  const { MAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const size = useWindowSize();
  const history = useHistory();
  const [loading, setLoading] = useState();
  const [total, setTotal] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [dataSource, setDataSource] = useState();
  const printRef = useRef();
  const printQrRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handlePrintQr = useReactToPrint({
    content: () => printQrRef.current,
  });
  const columns = [
    {
      title: "Charge Code",
      dataIndex: "COD_CHG",
      key: "COD_CHG",
    },
    {
      title: "Quantity",
      dataIndex: "QTY",
      key: "QTY",
    },
    {
      title: "Gross Amt",
      dataIndex: "AMT_GROSS",
      key: "AMT_GROSS",
      className: "awb-table-currency",
    },
    {
      title: "VAT Amt",
      dataIndex: "AMT_TAX",
      key: "AMT_TAX",
      className: "awb-table-currency",
    },
    {
      title: "Total Amt",
      dataIndex: "TOTAL_AMT",
      key: "TOTAL_AMT",
      className: "awb-table-currency",
    },
    {
      title: "Paid",
      dataIndex: "STATUS",
      key: "STATUS",
      width: "5%",
    },
    {
      title: "Payment mode",
      dataIndex: "COD_PAY_MOD",
      key: "COD_PAY_MOD",
      width: "5%",
    },
    {
      title: "Payable by",
      dataIndex: "FLG_BIL_TO",
      key: "FLG_BIL_TO",
      width: "5%",
    },
    {
      title: "Invoice No",
      dataIndex: "INV_NO",
      key: "INV_NO",
    },
  ];
  const showData = async () => {
    if (info !== undefined) {
      const {
        COD_QUE_NUM_CHK,
        FLG_IMP_EXP,
        COD_ORI,
        COD_FDS,
        QTY_TOT_PCS,
        QTY_GRS_WGT,
        QTY_GRS_RE_WGT,
        SHC1,
        SHC2,
        SHC3,
        CNE_CODE,
        CNE_NAME,
        AGENT_CODE,
        AGENT_NAME,
      } = info;
      form.setFieldsValue({
        queueNo: COD_QUE_NUM_CHK,
        awbFirst: MAWB.first,
        awbLast: MAWB.last,
        hawb: MAWB.hawb,
        impExp: FLG_IMP_EXP,
        org: COD_ORI,
        des: COD_FDS,
        pcs: QTY_TOT_PCS,
        weight: QTY_GRS_WGT,
        "re-weight": QTY_GRS_RE_WGT === 0 ? "" : QTY_GRS_RE_WGT,
        "H/M": MAWB.hawb !== "" ? "H" : "M",
        SHC1: SHC1,
        SHC2: SHC2,
        SHC3: SHC3,
        consigneeCode: CNE_CODE,
        consigneeName: CNE_NAME,
        agentCode: AGENT_CODE,
        agentName: AGENT_NAME,
      });
    }
    if (chargeInfo !== undefined) {
      try {
        let data = [];
        let total = 0;
        let totalGross = 0;
        let totalTax = 0;
        //eslint-disable
        chargeInfo.map((dt) => {
          data.push({
            COD_CHG: dt.COD_CHG,
            QTY: dt.QTY,
            AMT_GROSS: dt.AMT_GROSS.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            WAIVE_AMT: dt.WAIVE_AMT.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            AMT_TAX: dt.AMT_TAX.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            TOTAL_AMT: (dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            STATUS: dt.STATUS,
            COD_PAY_MOD: dt.COD_PAY_MOD,
            FLG_BIL_TO: dt.FLG_BIL_TO,
            INV_NO: dt.INV_SRL_NO + "/" + dt.INV_NO,
          });
          total += dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX;
          totalGross += dt.AMT_GROSS;
          totalTax += dt.AMT_TAX;
        });
        setTotal(total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        setTotalGross(
          totalGross.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setTotalTax(totalTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setDataSource(data);
      } catch (err) {
        notification.error({ message: "Lỗi", style: { marginRight: "-50%" } });
      }
    }
  };
  const nextTab = () => {
    if (status === "AQ")
      warning({
        title: "Thông báo",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn cần phải tính phí để tiếp tục!",
      });
    else props.setActiveKey("invoice-info");
  };
  const prevTab = () => {
    props.setActiveKey("receiver-info");
  };
  const submit = async (values) => {
    confirm({
      title: "Thông báo",
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có đồng ý tính phí lưu kho đến ngày ${moment(
        info.DAT_DLV
      ).format("DD/MM/YYYY HH:mm")} hay không?`,
      async onOk() {
        try {
          setLoading(true);
          const createBillChargeRes = await IMP_WEB_CHK_CRE_BIL_CHG_OBJ(
            {
              COD_AWB_PFX: MAWB.first,
              COD_AWB_NUM: MAWB.last,
              COD_AWB_SFX: MAWB.suffix,
              COD_HWB_NUM: MAWB.hawb,
            },
            token
          );
          let data = [];
          let total = 0;
          let totalGross = 0;
          let totalTax = 0;
          createBillChargeRes.data.P_RET_IMP_WEB_BIL_CHG_COLL.mCol.map((dt) => {
            data.push({
              COD_CHG: dt.COD_CHG,
              QTY: dt.QTY,
              AMT_GROSS: dt.AMT_GROSS.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              WAIVE_AMT: dt.WAIVE_AMT.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              AMT_TAX: dt.AMT_TAX.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              TOTAL_AMT: (dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              STATUS: dt.STATUS,
              COD_PAY_MOD: dt.COD_PAY_MOD,
              FLG_BIL_TO: dt.FLG_BIL_TO,
              INV_NO: dt.INV_SRL_NO + "/" + dt.INV_NO,
            });
            total += dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX;
            totalGross += dt.AMT_GROSS;
            totalTax += dt.AMT_TAX;
          });
          setTotal(total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

          setTotalGross(
            totalGross.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
          setTotalTax(
            totalTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
          setDataSource(data);
          await loadData();
          setLoading(false);
          if (
            ["FN", "OK", "AP"].includes(
              createBillChargeRes.data.P_CURR_OBJ_CHK.COD_STS
            )
          )
            notification.success({
              message: "Đã hoàn tất làm thủ tục cho lô hàng này!",
              style: { marginRight: "-50%" },
            });
        } catch (err) {
          // console.log(err);
          if (err.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          } else
            notification.error({
              message: err.response.data.error.slice(
                3,
                err.response.data.error.length
              ),
              style: { marginRight: "-50%" },
            });
        }
      },
      okType: "danger",
    });
  };
  useEffect(() => {
    showData();
  }, [info, chargeInfo]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col span={1}>
          <h2>AWB</h2>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          span={23}
        >
          <Button
            className="btn-payment"
            style={{ width: "fit-content", marginRight: "5px" }}
            onClick={prevTab}
          >
            {/* {t("header.submit")} */} Quay lại
          </Button>
          <Button
            className="btn-payment"
            style={{ width: "fit-content", marginRight: "5px" }}
            onClick={() => form.submit()}
            disabled={status !== "AQ"}
          >
            {/* {t("header.submit")} */} Tính phí
          </Button>
          <Button
            className="btn-payment"
            style={{ width: "fit-content" }}
            onClick={nextTab}
          >
            {/* {t("header.submit")} */} Tiếp tục
          </Button>
        </Col>
      </Row>
      <Form
        className="awb-payment"
        layout="vertical"
        onFinish={submit}
        form={form}
        autoComplete="off"
      >
        <Row gutter={[5, 0]}>
          <Col span={24} md={2}>
            <Form.Item name="queueNo" label="Queue No">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={3} lg={2}>
            <Form.Item name="awbFirst" label="AWB Number">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item label=" " name="awbLast">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={1}>
            <Form.Item name="H/M" label="H/M">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="hawb" label="HWB Number">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={1}>
            <Form.Item name="impExp" label="IMP/EXP">
              <Input readOnly />
            </Form.Item>
          </Col>
          {info.CNE_CODE === "00011616" &&
          (info.AGENT_CODE === "00014323" || info.AGENT_CODE === "00016556")
            ? status !== "NY" &&
              status !== "XX" && (
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  span={24}
                  md={7}
                >
                  <QRCode
                    value={
                      status !== "NY" && status !== "XX"
                        ? info.COD_REF_NUM
                        : "0000000000"
                    }
                  />
                </Col>
              )
            : status !== "NY" &&
              status !== "XX" && (
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  span={24}
                  md={6}
                >
                  <QRCode
                    value={
                      status !== "NY" && status !== "XX"
                        ? info.COD_REF_NUM
                        : "0000000000"
                    }
                  />
                </Col>
              )}
          {info && (
            <Col span={0}>
              <BarcodeToPrint
                ref={printRef}
                MAWB={{
                  first: MAWB.first,
                  last: MAWB.last,
                  suffix: MAWB.suffix,
                }}
                HAWB={MAWB.hawb}
                pcs={info.QTY_TOT_PCS}
                weight={info.QTY_GRS_WGT}
                DAT_CRE={info.DAT_CRE}
                DAT_DLV={info.DAT_DLV}
                DLV_ORD_RCV_ID={info.DLV_ODR_RCV_ID}
                DLV_ORD_RCV_NAME={info.DLV_ODR_RCV_NAM}
                status={status}
                value={
                  status !== "NY" && status !== "XX"
                    ? info.COD_REF_NUM
                    : "0000000000"
                }
              />
              <QrCodeToPrint
                ref={printQrRef}
                MAWB={{
                  first: MAWB.first,
                  last: MAWB.last,
                  suffix: MAWB.suffix,
                }}
                HAWB={MAWB.hawb}
                pcs={info.QTY_TOT_PCS}
                weight={info.QTY_GRS_WGT}
                DAT_CRE={info.DAT_CRE}
                DAT_DLV={info.DAT_DLV}
                DLV_ORD_RCV_ID={info.DLV_ODR_RCV_ID}
                DLV_ORD_RCV_NAME={info.DLV_ODR_RCV_NAM}
                status={status}
                value={
                  status !== "NY" && status !== "XX"
                    ? info.COD_REF_NUM
                    : "0000000000"
                }
              />
            </Col>
          )}
          {info.CNE_CODE === "00011616" &&
          (info.AGENT_CODE === "00014323" || info.AGENT_CODE === "00016556")
            ? status !== "NY" &&
              status !== "XX" && (
                <Col
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                  span={24}
                  md={2}
                >
                  <Space>
                    <Form.Item>
                      <Button
                        className="btn-payment"
                        style={{ width: "fit-content" }}
                        onClick={handlePrint}
                      >
                        {/* {t("header.submit")} */} Print Barcode
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="btn-payment"
                        style={{ width: "fit-content" }}
                        onClick={handlePrintQr}
                      >
                        {/* {t("header.submit")} */} Print QR
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              )
            : status !== "NY" &&
              status !== "XX" && (
                <>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={24}
                    md={2}
                    xl={2}
                  >
                    <Form.Item>
                      <Button
                        className="btn-payment"
                        style={{
                          width: "fit-content",
                          marginTop: size === "sm" ? "15px" : "0px",
                        }}
                        onClick={handlePrint}
                      >
                        {/* {t("header.submit")} */} Print Barcode
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: ["xl", "sm"].includes(size)
                        ? "center"
                        : "flex-end",
                      top: ["xl", "sm"].includes(size) ? "0px" : "-80px",
                    }}
                    span={24}
                    md={23}
                    xl={2}
                  >
                    <Form.Item>
                      <Button
                        className="btn-payment"
                        style={{ width: "fit-content" }}
                        onClick={handlePrintQr}
                      >
                        {/* {t("header.submit")} */} Print QR
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              )}
        </Row>
        <Row style={{ marginTop: "-10px" }} gutter={[5, 0]}>
          <Col span={24} md={2}>
            <Form.Item name="org" label="Org">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={2}>
            <Form.Item name="des" label="Des">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={3}>
            <Form.Item name="pcs" label="Pieces">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={3}>
            <Form.Item name="weight" label="Weight">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={3}>
            <Form.Item name="re-weight" label="Re-Weight">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={2} md={2}>
            <Form.Item name="SHC1" label="SHC">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={2} md={2}>
            <Form.Item label=" " name="SHC2">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={2} md={2}>
            <Form.Item label=" " name="SHC3">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
        <hr style={{ marginTop: "-10px" }} />
        <div className="awb-payment">
          <Row>
            <Col span={12}>
              <h2>Tên người gửi/ nhận hàng</h2>
              <Row gutter={[5, 0]}>
                <Col span={6}>
                  <Form.Item name="consigneeCode" label="Code">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="consigneeName" label="Name">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <h2>Công ty được uỷ quyền nhận hàng</h2>
              <Row gutter={[5, 0]}>
                <Col span={6}>
                  <Form.Item name="agentCode" label="Code">
                    <Input readOnly />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="agentName" label="Name">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
      <div>
        <p
          style={{
            fontStyle: "bold",
            fontWeight: "700",
            fontSize: "20",
            lineHeight: "23px",
          }}
        >
          Table of Charge Details
        </p>
        <Row>
          <Col className="table-payment" span={24}>
            <Table
              className="table-payment-charge-detail"
              pagination={false}
              dataSource={dataSource}
              loading={loading}
              columns={columns}
              summary={() => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1} colSpan={2}>
                        Tổng:
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="awb-table-currency"
                        index={1}
                      >
                        {totalGross}
                      </Table.Summary.Cell>

                      <Table.Summary.Cell
                        className="awb-table-currency"
                        index={6}
                      >
                        {totalTax}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="awb-table-currency"
                        index={0}
                        colSpan={1}
                      >
                        {total}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="awb-table-currency"
                        index={0}
                        colSpan={4}
                      ></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
